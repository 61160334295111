import { environment } from "@telespot/shared/environment";
import { File as ParseFile, Object as ParseObject } from "parse";

import { Analysis } from "../analysis/analysis.model";
import { AssetType } from "../asset-type/asset-type.model";
import { AnalysisData } from "../data/analysis-data";
import { AssetStateName } from "../data/asset-state-name";
import { Device } from "../device/device.model";
import { MethodType } from "../method-type/method-type.model";
import { Organization } from "../organization/organization.model";
import { User } from "../user/user.model";
import { IAssetData } from "./asset.data";

export interface IAssetState {
  state: AssetStateName;
}

/**
 * Represents a single image / video. Several Assets form a {@link Sample}
 *
 * @export
 * @class Asset
 * @extends {ParseObject}
 */
export class Asset extends ParseObject {
  static className = "Asset";

  private _analysis: Analysis[];

  analysisData: AnalysisData[];

  constructor({
    organization,
    device,
    methodType,
    assetType,
    isMarked,
    assetFile,
  }: {
    organization?: Organization;
    device?: Device;
    methodType?: MethodType;
    assetType?: AssetType;
    isMarked?: boolean;
    assetFile?: string;
  } = {}) {
    super(Asset.className);
    this.organization = organization;
    this.device = device;
    this.methodType = methodType;
    this.assetType = assetType ?? methodType?.assetType;
    this.isMarked = isMarked;
    this.assetFile = assetFile;
  }

  private _localFile: ParseFile;

  get data(): IAssetData {
    return this.get("data");
  }
  set data(data: IAssetData) {
    this.set("data", data);
  }

  get isMarked(): boolean {
    return this.get("fav");
  }
  set isMarked(isMarked: boolean) {
    this.set("fav", isMarked);
  }

  /**
   * @deprecated
   *
   * @type {ParseFile}
   * @memberof Asset
   */
  get file(): ParseFile {
    return this.get("file");
  }
  set file(file: ParseFile) {
    this.set("file", file);
  }

  get assetFileName(): string {
    return this.get("assetFile") ?? this.file?.name();
  }

  get assetFileURL(): string {
    return this.assetFileName
      ? encodeURI(`${environment.api.url}/files/${this.assetFileName}`)
      : undefined;
  }
  get assetFile(): string {
    return this.assetFileName;
  }
  set assetFile(name: string) {
    this.set("assetFile", name);
  }

  get fileName(): string {
    const fullFileName = this.assetFileName || this.file.name();
    return encodeURI(fullFileName.substr(0, fullFileName.lastIndexOf(".")));
  }

  /**
   * Returns the url to the appropriate thumbnail, depending on the Asset type
   *
   * @readonly
   * @type {string}
   * @memberof Asset
   */
  get thumbnail(): string {
    const imageRegex = /.(png|bmp|jpg|jpeg|dcm)$/gim;
    const videoRegex = /.(mp4|mov|avi|mpeg)$/gim;
    const fileName = this.assetFileName;
    return imageRegex.test(fileName)
      ? `${environment.api.url}/files/pyramids/${this.fileName}/thumbnail`
      : videoRegex.test(fileName)
      ? `${environment.api.url}/files/videos/${this.fileName}/thumbnail`
      : `@app/assets/media/image_placeholder.svg`;
  }

  /**
   * Returns a video preview (gif) URL, if the Asset is of video type
   *
   * @readonly
   * @type {string}
   * @memberof Asset
   */
  get preview(): string {
    return `${environment.api.url}/files/videos/${this.fileName}/preview`;
  }

  /**
   * Returns the pyramids endpoint for an image Asset
   *
   * @readonly
   * @type {string}
   * @memberof Asset
   */
  get tiles(): string {
    return `${environment.api.url}/files/pyramids/${this.fileName}`;
  }

  get heatmaps(): string {
    return `${environment.api.url}/files/heatmaps/${this.fileName}`;
  }

  get tilePath(): string {
    return this.fileName + "/tiles";
  }

  /**
   * Returns the URL of the pyramid info file (JSON), which contains the zoom levels, image dimensions, etc
   *
   * @readonly
   * @type {string}
   * @memberof Asset
   */
  get infoFile(): string {
    return `${environment.api.url}/files/pyramids/${this.fileName}/info`;
  }

  get overlaysInfoEndpoint(): string {
    return `${environment.api.url}/files/${this.fileName}/masks`;
  }

  get states(): IAssetState {
    return this.get("state");
  }
  set states(value: IAssetState) {
    this.set("state", value);
  }

  /**
   * Local/offline file object, for uploading Assets
   *
   * @type {ParseFile}
   * @memberof Asset
   */
  get localFile(): ParseFile {
    return this._localFile;
  }
  set localFile(file: ParseFile) {
    this._localFile = file;
  }

  get name(): string {
    return this.get("name") ?? "";
  }
  set name(newName: string) {
    this.set("name", newName);
  }

  /**
   * The MethodType used to acquire this Asset & containing {@link Sample}
   *
   * @type {MethodType}
   * @memberof Asset
   */
  get methodType(): MethodType {
    return this.get("methodType");
  }
  set methodType(newMethodType: MethodType) {
    this.set("methodType", newMethodType);
  }

  get createdBy(): User {
    return this.get("createdBy");
  }

  set organization(value: Organization) {
    this.set("organization", value);
  }
  get organization(): Organization {
    return this.get("organization");
  }

  set assetType(value: AssetType) {
    this.set("assetType", value);
  }
  get assetType(): AssetType {
    return this.get("assetType");
  }

  get numAnalysis(): number {
    return this.get("numAnalysis");
  }

  get device(): Device {
    return this.get("device");
  }
  set device(device: Device) {
    this.set("device", device);
  }

  get appVersion(): string {
    return this.get("appVersion");
  }

  addState(state: IAssetState) {
    this.states = state;
  }

  get currentState(): IAssetState {
    const states = this.states;
    return states ? states : undefined;
  }

  // TODO: review ?
  get analysis(): Analysis[] {
    return this._analysis;
  }
  set analysis(analysis: Analysis[]) {
    this._analysis = analysis;
  }

  public static createWithoutData(id: string): Asset {
    return ParseObject.fromJSON({
      className: this.className,
      objectId: id,
    }) as Asset;
  }
}
