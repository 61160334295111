import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { SafeUrl } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  AnalysisLabel,
  DragDropService,
  POI,
  ProtocolSpecification,
  ROI,
  changeCropLabels,
  dragCrop,
  groupPositionTasks,
  selectRois,
} from "@telespot/analysis-refactor/data-access";

import { MosaicService } from "@telespot/web-core";
import { Observable, Subject, of } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";

export interface GroupedMosaic {
  category: string;
  labels: { [label: string]: CategoryLabelData };
}

export interface CategoryLabelData {
  uuid: string;
  color: string;
  crops: CropInfo[];
}

export interface CropInfo {
  roiID: string;
  findingId: string;
  crop: Observable<SafeUrl>;
}
@Component({
  selector: "ts-sample-analysis-mosaics",
  templateUrl: "./sample-analysis-mosaics.component.html",
  styleUrls: ["./sample-analysis-mosaics.component.scss"],
})
export class SampleAnalysisMosaicsComponent
  implements OnInit, OnChanges, OnDestroy
{
  public unlabeledText = {
    en: "Unlabeled",
    es: "Sin etiquetar",
    fr: "Non etiqueté",
    pt: "Não rotulado",
  };
  // Contextual Menu variables
  public contextMenuVisible = false;
  public contextMenuPosition = { x: 0, y: 0 };
  public selectedItemLabels: string[] | null = [];
  @ViewChild("contextMenu", { read: ElementRef })
  private contextMenuRef: ElementRef;

  // Protocol and Mosaics variables
  @Input() protocol: ProtocolSpecification[] = [];
  public readonly rois$ = this._store.select(selectRois);
  public localGroupedMosaics: GroupedMosaic[] = [];
  public assetProtocolTasks;
  public currentLang;

  public assetProtocol$ = this._store.select(groupPositionTasks);

  public groupedMosaics$: Observable<GroupedMosaic[]>;
  private _rois: (ROI | POI)[] = [];
  private _selectedCrop: CropInfo;

  // Drag and Drop variables
  private labelDict: { [label: string]: string } = {};

  // Component LifeCycle variables
  private destroy$ = new Subject<void>();
  private registeredDropListIds: string[] = [];

  constructor(
    public dragDropService: DragDropService,
    private _mosaicService: MosaicService,
    private _cdr: ChangeDetectorRef,
    private _store: Store,
    private translateService: TranslateService
  ) {
    this.currentLang =
      localStorage.getItem("user_language") ||
      this.translateService.currentLang;
  }

  /* COMPONENT LYFECYCLE MANAGEMENT FUNCTIONS */

  ngOnChanges(changes: SimpleChanges) {
    if (changes.protocol?.currentValue) {
      // Using the assetProtocol$ observable, we set up a dynamic pipeline with switchMap
      // that will switch to a different observable whenever the value of assetProtocol$ changes.
      // In this case, it switches to the observable returned by createGroupedMosaics function
      // whenever there's an update in ROIs or the asset protocol.
      this.groupedMosaics$ = this.assetProtocol$.pipe(
        switchMap((assetProtocol) =>
          this.rois$.pipe(
            switchMap((rois) => this.createGroupedMosaics(rois, assetProtocol))
          )
        )
      );
    }
  }

  ngOnInit() {
    // We establish a subscription to the groupedMosaics$ observable.
    // The subscription is set to automatically unsubscribe when the destroy$ emits, preventing memory leaks.
    this.groupedMosaics$
      .pipe(takeUntil(this.destroy$))
      .subscribe((groupedMosaics) => {
        // First, we clear any previously registered drop list IDs to start afresh.
        this.registeredDropListIds.forEach((id) =>
          this.dragDropService.removeDropListId(id)
        );
        this.registeredDropListIds = [];

        // Then, we register new drop list IDs for drag and drop functionality
        // based on the current set of grouped mosaics.
        groupedMosaics.forEach((categoryObj) => {
          Object.keys(categoryObj.labels).forEach((label) => {
            this.dragDropService.addDropListId(label); // Register a drop list ID
            this.registeredDropListIds.push(label); // Remember the ID for later cleanup
          });
        });
      });

    this.assetProtocol$.pipe(takeUntil(this.destroy$)).subscribe(
      (groups) =>
        (this.assetProtocolTasks = groups.reduce((acc, protocol) => {
          return acc.concat(protocol.tasks);
        }, []))
    );

    this.rois$
      .pipe(takeUntil(this.destroy$))
      .subscribe((rois) => (this._rois = rois));
  }

  ngOnDestroy() {
    // Next two lines signal any subscriber to 'destroy$' observable to complete
    // their work before the component is gone for good. Helps avoid memory leaks.
    this.destroy$.next();
    this.destroy$.complete();

    // We also iterate through our registered drop list IDs to deregister them,
    // cleaning up any handlers tied to them. This ensures our dragService doesn't litter around.
    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
  }

  /* PROTOCOL AND MOSAICS FUNCTIONS */

  private createGroupedMosaics(
    rois: (ROI | POI)[],
    protocols
  ): Observable<GroupedMosaic[]> {
    const tempGroupedMosaics: GroupedMosaic[] = [];
    // Reset the label dictionary
    this.labelDict = {};

    protocols
      .reduce((acc, protocol) => {
        return acc.concat(protocol.tasks);
      }, [])
      .forEach((task) => {
        const categoryObj: Partial<GroupedMosaic> = {
          category: task.name, // Assigning the name of the task as the category name
          labels: {}, // Preparing an object to hold label data
        };

        task.options?.forEach((option) => {
          // Destructure the needed properties from the option for ease of access.
          const { name: label, uuid, color } = option;

          // Construct the label dictionary for Drag and Drop actions
          this.labelDict[label] = uuid;

          // Filter and map ROIs to create an array of crop URLs for this option.
          const crops: CropInfo[] = rois
            .filter((roi: ROI | POI) =>
              roi.labels.some(
                (analysisLabel: AnalysisLabel) =>
                  analysisLabel.labels &&
                  analysisLabel.labels[uuid] !== undefined // Checking if the roi has the specific uuid as a label
              )
            )
            .map((roi: ROI | POI): CropInfo => {
              const labelGroup = roi.labels.find(
                (analysisLabel: AnalysisLabel) =>
                  Object.keys(analysisLabel.labels).includes(uuid)
              );
              return {
                roiID: roi.id, // Aquí está la magia, asignando el id
                findingId: labelGroup.findingId,
                crop: this._mosaicService.getCropURL(roi.cropFileName), // Y aquí sigue la rutina, obteniendo la URL}
              };
            });

          // If this task option has associated crops, add it to the labels.
          if (crops.length > 0) {
            categoryObj.labels = {
              ...categoryObj.labels, // Include previous labels in the category
              [label]: { uuid, color, crops }, // Add new label with crops
            };
          }
        });

        // If there are labels added to the category object, include it in the array.
        if (categoryObj.labels && Object.keys(categoryObj.labels).length > 0) {
          tempGroupedMosaics.push(categoryObj as GroupedMosaic);
        }
      });

    const cropsUnlabeled = rois
      .flatMap((roi) => [
        ...roi.labels.map((l) => ({
          ...l,
          roiId: roi.id,
          crop: this._mosaicService.getCropURL(roi.cropFileName),
        })),
      ])
      .filter((label) => Object.keys(label.labels).length === 0);

    if (cropsUnlabeled.length > 0) {
      tempGroupedMosaics.push({
        category: this.unlabeledText[this.currentLang ?? "en"],
        labels: {
          ["unlabeled"]: {
            color: "#F08F43",
            uuid: undefined,
            crops: [
              ...cropsUnlabeled.map((c) => ({
                roiID: c.roiId,
                findingId: c.findingId,
                crop: c.crop,
              })),
            ],
          },
        },
      });
    }

    return of(tempGroupedMosaics); // Return the grouped mosaic data as an observable
  }

  /* CONTEXTUAL MENU FUNCTIONS */

  // HostListener decorates the method below to listen to global 'click' events.
  // The "$event" argument is automatically passed containing the event details.
  @HostListener("document:click", ["$event"])
  documentClick(event: MouseEvent): void {
    // Check if the click was outside our context menu's element
    if (!this.contextMenuRef?.nativeElement.contains(event.target)) {
      this.closeContextMenu(); // If it was outside, close the context menu.
    }
  }

  public openContextMenu(
    event: MouseEvent,
    label: string,
    cropInfo: CropInfo
  ): void {
    event.preventDefault(); // Prevent the browser's default right-click menu from opening.
    event.stopPropagation(); // Stop this event from bubbling up to other event handlers.

    // Set the preliminary position of the context menu based on the mouse event's coordinates.
    this.contextMenuPosition.x = event.clientX; // X-coordinate where the click occurred.
    this.contextMenuPosition.y = event.clientY; // Y-coordinate where the click occurred.

    this._selectedCrop = cropInfo;
    const labels = Object.keys(
      this._rois
        .find((roi) => {
          return roi.id === cropInfo.roiID;
        })
        .labels.find((label) => label.findingId === cropInfo.findingId).labels
    );
    labels.forEach((uuid) =>
      this.selectedItemLabels.push(
        Object.keys(this.labelDict).find(
          (label) => this.labelDict[label] === uuid
        )
      )
    );

    this.contextMenuVisible = true; // Toggle visibility of context menu to true.

    // Trigger change detection to ensure that the context menu is rendered in the DOM.
    this._cdr.detectChanges();

    // After Angular has done with change detection, we can safely measure the actual
    // dimensions of the context menu for further adjustment, if needed.
    const rect = this.contextMenuRef.nativeElement.getBoundingClientRect();
    const bottomSpace = window.innerHeight - event.clientY; // Space available below the click point.

    // If there isn't enough space at the bottom of the window for the menu,
    // adjust its position upward by the height of the menu.
    if (rect.height > bottomSpace) {
      this.contextMenuPosition.y -= rect.height;
    }

    // Re-trigger change detection after adjusting position to ensure the adjustment
    // is rendered on the screen properly.
    this._cdr.detectChanges();
  }

  public closeContextMenu(): void {
    this.contextMenuVisible = false; // Hide the context menu
    this.selectedItemLabels = []; // Clear the selected item label.
    this._selectedCrop = null;
  }

  public onClick(label: any): void {
    const labelName = Object.keys(this.labelDict).find(
      (l) => this.labelDict[l] === label.uuid
    );

    if (!this.selectedItemLabels.includes(labelName))
      this.selectedItemLabels.push(labelName);
    else {
      this.selectedItemLabels = this.selectedItemLabels.filter(
        (label) => label !== labelName
      );
    }

    this._store.dispatch(
      changeCropLabels({
        roiId: this._selectedCrop.roiID,
        findingId: this._selectedCrop.findingId,
        newLabel: label.uuid,
      })
    );
  }

  /* DRAG AND DROP FUNCTIONS */

  public onDrop(event: CdkDragDrop<CropInfo[]>): void {
    // Check if the drag and drop event occurred within the same container.
    if (event.previousContainer === event.container) {
      // Since the item was moved within the same container, simply rearrange the item's position in the array.
      moveItemInArray(
        event.container.data, // Data array containing 'CropInfo' items.
        event.previousIndex, // Previous index of the dragged item.
        event.currentIndex // Current/new index where the item is dropped.
      );
    } else {
      // Extract 'CropInfo' object from the dragged item's payload.
      const draggedCropInfo: CropInfo = event.item.data;
      const roiID: string = draggedCropInfo.roiID;
      const origin = this.labelDict[event.previousContainer.id];
      const dest = this.labelDict[event.container.id];

      // Dispatch an action to update the store, indicating that the crop has been dragged from one label to another.
      this._store.dispatch(
        dragCrop({ roiId: roiID, previousLabels: [origin], newLabels: [dest] })
      );
    }
  }

  onDragStart(): void {
    if (this.contextMenuVisible) this.closeContextMenu();
  }

  /* HELPER FUNCTIONS */

  public objectKeys(obj: any): string[] {
    return Object.keys(obj); // Convert object keys into a string array and return them.
  }

  public getCrop(roiId: string) {
    const url = localStorage.getItem(`crop_${roiId}`);
    if (!url) {
      return null;
    }
    const sanitizedUrl = this._mosaicService.getCropSanitizedUrl(url);

    return sanitizedUrl;
  }
}
