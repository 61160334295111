import {
  Algorithms,
  Analysis,
  Finding,
  PipelineStep,
  StepTask,
  User,
} from "@telespot/sdk";
import {
  AnalysisChange,
  IFinding,
  POI,
  ProtocolSpecification,
  ROI,
} from "../../state";
import { ISampleItem } from "../../models/i-sample-item";
import { RoiItem } from "@telespot/web-core";
import uid from "uid";

export class FindingMapper {
  public static toStateFindings(finding: Finding): IFinding {
    let data;
    switch (finding.type) {
      case StepTask.TEXT:
        data = finding.data?.content[0]?.value ?? "";
        break;
      case StepTask.CLASSIFICATION: {
        const findingData = Object.keys(
          finding.data?.content[0]?.options || {}
        );
        data = finding.pipelineStep.params?.multiple
          ? findingData
          : findingData[0];
        break;
      }
      case StepTask.SEGMENTATION:
        data = finding.data?.url;
        break;
      default:
        data = undefined;
        break;
    }
    return {
      id: finding.id,
      analysisId: finding.analysis.id,
      data,
      synced: true,
      taskId: finding.pipelineStep.id,
      assetId: finding.analysis.asset?.id,
      version: finding.version,
      uuid: finding.uuid,
      createdBy:
        finding.creatorEntity === "user"
          ? User.createWithoutData(finding?.creatorId).toPointer()
          : Algorithms.createWithoutData(finding?.creatorId).toPointer(),
    };
  }

  public static fromStateFinding(
    finding: IFinding,
    changes: AnalysisChange<string>[],
    rois: (ROI | POI)[],
    protocols: ProtocolSpecification[],
    assetsInfo: ISampleItem[]
  ) {
    const parseFinding = new Finding();
    const isNew = finding.id.includes("new:");
    const isCopy = finding.id.startsWith("copy:");

    const getAnalysisId = (id) =>
      id.startsWith("copy:") ? id.substring(id.indexOf("/replace:") + 9) : id;

    const isUser = finding.createdBy.className === User.className;

    const creatorEntity = isUser ? "user" : "algorithm";
    const creatorId = finding.createdBy.objectId;

    parseFinding.creatorId = creatorId;
    parseFinding.creatorEntity = creatorEntity;

    parseFinding.pipelineStep = PipelineStep.createWithoutData(
      finding.taskId
    ) as PipelineStep;

    const analysisId =
      changes.find((c) => c.previous === finding.analysisId)?.current ??
      getAnalysisId(finding.analysisId);

    parseFinding.analysis = analysisId
      ? (Analysis.createWithoutData(analysisId) as Analysis)
      : null;

    const taskType = protocols
      .reduce((acc, p) => [...acc, ...p.tasks], [])
      .find((t) => t.stepId === finding.taskId)?.type;
    parseFinding.type = taskType;

    if (isCopy) {
      parseFinding.origin = Finding.createWithoutData(
        finding.id.substring(
          finding.id.indexOf("copy:") + 5,
          finding.id.indexOf("/replace:")
        )
      ) as Finding;
    }
    const assetInfo = finding.assetId
      ? assetsInfo.find((assetInfo) => assetInfo.assetId === finding.assetId)
      : undefined;

    parseFinding.version = isNew ? 1 : finding.version + 1;
    parseFinding.data = FindingMapper.toFindingData(
      finding,
      taskType,
      rois,
      assetInfo
    );

    parseFinding.uuid = finding.uuid;

    return parseFinding;
  }

  public static getFindingsFromMosaicRois(items: RoiItem[]) {
    const uniqueFindings = [];

    items.map((item) => {
      const isIncluded = uniqueFindings.find(
        (finding) => finding.id === item.findingVersionId
      );

      if (!isIncluded) {
        uniqueFindings.push({
          id: item.findingVersionId,
          analysisId: item.analysisId,
          data: undefined,
          synced: true,
          taskId: item.stepId,
          assetId: item.assetId,
          version: item.findingVersion,
          uuid: item.findingId,
          createdBy:
            item.creatorEntity === "user"
              ? User.createWithoutData(item?.creatorId).toPointer()
              : Algorithms.createWithoutData(item?.creatorId).toPointer(),
        });
      }
    });

    return uniqueFindings;
  }

  public static getStateRoisFromMosaicRois(
    items: RoiItem[],
    refstripItems: ISampleItem[]
  ) {
    const rois = items.map((item) => {
      let labels = item.labels;
      const createdByAI = item.creatorEntity === "algorithm";

      if (createdByAI) {
        //Get label max probability for AI rois
        const values = Object.values(labels || {});

        if (!values.length) labels = {};
        const maxProb = Math.max(...values);

        const indices = values.reduce((acc, val, index) => {
          if (val === maxProb) {
            acc.push(index);
          }
          return acc;
        }, []);
        if (indices.length > 0) {
          labels = indices.reduce((acc, index) => {
            acc[Object.keys(labels)[index]] = maxProb;
            return acc;
          }, {});
        } else {
          labels = {};
        }
      }

      const refItem = refstripItems.find(
        (elem) => elem.assetId === item.assetId
      );

      const assetWidth = refItem?.width ?? 1;
      const assetHeight = refItem?.height ?? 1;

      return {
        x: item.x0 * assetWidth,
        y: item.y0 * assetHeight,
        w: (item.x1 - item?.x0) * assetWidth,
        h: (item?.y1 - item?.y0) * assetHeight,
        id: uid(6),
        selected: false,
        isAIResult: false,
        cropFileName: item.filename,
        labels: [
          {
            analysisId: item.analysisId,
            findingId: item.findingVersionId,
            labels,
          },
        ],
      };
    });

    return rois;
  }

  public static toFindingData(
    finding: IFinding,
    type: string,
    rois?: (ROI | POI)[],
    assetInfo?: ISampleItem
  ) {
    let updatedData;
    const findingROIs = [];
    (rois || []).forEach((r) => {
      const labels =
        (r.labels || []).find((l) => l.findingId === finding.id)?.labels ?? {};

      if (Object.keys(labels).length < 1) return;
      findingROIs.push({
        x0: assetInfo?.width ? (r.x / assetInfo.width).toFixed(10) : r.x,
        y0: assetInfo?.height ? (r.y / assetInfo.height).toFixed(10) : r.y,
        x1: assetInfo?.width
          ? ((r.x + r["w"] ?? 0) / assetInfo.width).toFixed(10)
          : r.x + r["w"] ?? 0,
        y1: assetInfo?.height
          ? ((r.y + r["h"] ?? 0) / assetInfo.height).toFixed(10)
          : r.y + r["h"] ?? 0,
        labels,
      });
    });

    switch (type) {
      case StepTask.TEXT:
        updatedData = { content: [{ value: finding.data ?? "" }] };
        break;
      case StepTask.CLASSIFICATION:
        updatedData = {
          content: [
            {
              options:
                finding.data instanceof Array
                  ? finding.data.reduce(
                      (acc, curr) => ({ ...acc, [curr]: 1 }),
                      {}
                    )
                  : { [finding.data]: 1 },
            },
          ],
        };
        break;
      case StepTask.POSITION:
        updatedData = {
          content: [...findingROIs],
        };
        break;
      case StepTask.ROIDETECTION:
        updatedData = {
          content: [...findingROIs],
        };
        break;
      case StepTask.SEGMENTATION:
        updatedData = { url: "" };
        break;
      default:
        updatedData = undefined;
        break;
    }

    return updatedData;
  }
}
