import {
  Component,
  ChangeDetectionStrategy,
  Inject,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import {
  AiAnalysisService,
  SampleAnalysisService,
  discardAllChanges,
  enableMosaicView,
  hasUnlabeledRois,
  preSyncAnalysis,
  selectAsset,
  selectHasUnsavedChanges,
  selectMode,
  selectSyncingAnalysis,
} from "@telespot/analysis-refactor/data-access";
import { BreakpointObserver } from "@angular/cdk/layout";
import { FeaturesService } from "@telespot/web-core";
import { Platform } from "@angular/cdk/platform";
import {
  Observable,
  combineLatest,
  of,
  BehaviorSubject,
  Subject,
  EMPTY,
} from "rxjs";
import {
  TOsdActiveAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";
import { Sample, Asset } from "@telespot/sdk";
import {
  startWith,
  map,
  shareReplay,
  share,
  distinctUntilChanged,
  takeUntil,
  switchMap,
  take,
  tap,
  filter,
  mapTo,
} from "rxjs/operators";

import { SampleAnalysisPanelComponent } from "../sample-analysis-panel/sample-analysis-panel.component";
import { MobileAnalysisPanelComponent } from "../mobile-analysis-panel/mobile-analysis-panel.component";
import { Store } from "@ngrx/store";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ConfirmationDialogComponent, PRESETS } from "@shared/ui";

type EPanelContext = "ai" | "prioritize" | "analyst" | "secondOpinion";
@Component({
  selector: "ts-sample-analysis-tools",
  templateUrl: "./sample-analysis-tools.component.html",
  styleUrls: ["./sample-analysis-tools.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class SampleAnalysisToolsComponent {
  @Output() exitEvent = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @Output() download = new EventEmitter<void>();
  @Output() secondOpinion = new EventEmitter<void>();
  @Output() updateOrderIA = new EventEmitter<"default" | "ai">();
  @Output() toggleMode = new EventEmitter<string>();
  @Input() sample: Sample;
  @Input() numAssets$: Observable<number>;
  @Input() mosaicMode: boolean;

  @ViewChild(SampleAnalysisPanelComponent)
  analysisPanel: SampleAnalysisPanelComponent;
  @ViewChild(MobileAnalysisPanelComponent)
  mobileAnalysisPanel: MobileAnalysisPanelComponent;

  public readonly syncing$ = this.store.select(selectSyncingAnalysis);
  public readonly hasUnlabeledRois$ = this.store.select(hasUnlabeledRois);

  readonly processingAI$ = this._aiService.prioritizationStatus$.pipe(
    startWith("idle")
  );

  public readonly hasUnsavedData$ = this.store.select(selectHasUnsavedChanges);
  public readonly mode$ = this.store.select(selectMode);
  public readonly enableMosaicView$ = this.store.select(enableMosaicView);
  public readonly mosaicButtonDisabled$ = combineLatest([
    this.mode$,
    this.enableMosaicView$,
    this.syncing$,
    this._sampleAnalysisService.isViewMode$,
  ]).pipe(
    switchMap(([mode, mosaicViewEnabled, syncing, isViewMode]) => {
      const userDisabled = mode === "review" || isViewMode;
      const protocolDisabled = !mosaicViewEnabled;
      const syncDisabled = syncing;

      if (userDisabled || protocolDisabled || syncDisabled) {
        return of(true);
      }

      return of(false);
    })
  );

  private _dialogRef: MatDialogRef<any>;

  private _destroy$ = new Subject<void>();

  private _dirty: boolean;
  private _hasUnlabeledRois: boolean;

  constructor(
    private store: Store,
    private _aiService: AiAnalysisService,
    private _breakpoint: BreakpointObserver,
    private _sampleAnalysisService: SampleAnalysisService,
    private _featureService: FeaturesService,
    private _viewerService: ViewerService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    @Inject(Platform) private _platform: Platform
  ) {
    this.hasUnsavedData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((dirty) => (this._dirty = dirty));

    this.hasUnlabeledRois$
      .pipe(takeUntil(this._destroy$))
      .subscribe((hasUnlabeledRois) => {
        this._hasUnlabeledRois = hasUnlabeledRois;
      });
  }

  public sectorProcessing$ = this._aiService.sectorProcessing$;
  private _panelContext = new BehaviorSubject<string>("");
  // UI state
  availableAIanalysis$: Observable<boolean> = of(
    this._featureService.canUse("ai")
  );

  readonly selectedAsset$: Observable<Asset> = this.store.select(selectAsset);

  secondOpinionGuest: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  // REVIEW
  readonly isViewMode$ = this._sampleAnalysisService.isViewMode$.pipe(
    distinctUntilChanged(),
    share()
  );

  public readonly layout$: Observable<"mobile" | "web"> = combineLatest([
    this._breakpoint.observe(["(min-width: 600px)"]),
  ]).pipe(
    map(([breakpoint]) => breakpoint.matches),
    startWith(!(this._platform.ANDROID || this._platform.IOS)),
    map<boolean, "web" | "mobile">((web) => (web ? "web" : "mobile")),
    shareReplay({ bufferSize: 1, refCount: true })
  );

  get panelContext$() {
    return this._panelContext.asObservable();
  }

  togglePanel(panel: EPanelContext) {
    this._panelContext.next(
      this._panelContext.value !== panel ? panel : undefined
    );
  }

  public toggleViewerMode(mode: TOsdActiveAction) {
    this._viewerService.toggleViewerMode(mode);
  }

  public downloadAsset() {
    this._snackBar.open("Wait until download completes", null, {
      duration: 2000,
    });
  }

  saveEvent() {
    this.save.emit();
  }

  downloadEvent() {
    this.download.emit();
  }

  secondOpinionEvent() {
    this.secondOpinion.emit();
  }

  updateOrderEvent(event) {
    this.updateOrderIA.emit(event);
  }

  exit() {
    this.exitEvent.emit();
  }

  toggleAnalysisMode(mode: string) {
    if (this._hasUnlabeledRois && !this._dirty) {
      this.openDialog(PRESETS.DIALOG_UNLABELED_ROIS)
        .pipe(
          switchMap((result) => {
            if (result === "accept" || result === "default") {
              return this.waitForSyncingToComplete().pipe(mapTo(mode));
            } else {
              return EMPTY;
            }
          }),
          takeUntil(this._destroy$)
        )
        .subscribe((mode) => {
          this.toggleMode.emit(mode);
        });
    } else if (this._dirty) {
      this.openDialog(
        this._hasUnlabeledRois
          ? PRESETS.DIALOG_CHANGE_ANALYSIS_MODE_WITHOUT_SAVING_AND_UNLABELED
          : PRESETS.DIALOG_CHANGE_ANALYSIS_MODE_WITHOUT_SAVING
      )
        .pipe(
          switchMap((result) => {
            switch (result) {
              case "discard":
                this.store.dispatch(discardAllChanges());
                return of(mode);
              case "accept":
                this.store.dispatch(preSyncAnalysis());
                return this.waitForSyncingToComplete().pipe(mapTo(mode));
              case "cancel":
              default:
                return EMPTY;
            }
          }),
          takeUntil(this._destroy$)
        )
        .subscribe((mode) => {
          this.toggleMode.emit(mode);
        });
    } else {
      this.toggleMode.emit(mode);
    }
  }

  private openDialog(preset: any): Observable<any> {
    this._dialogRef = this._dialog.open(ConfirmationDialogComponent, preset);

    return this._dialogRef.afterClosed().pipe(
      tap(() => (this._dialogRef = undefined)),
      takeUntil(this._destroy$)
    );
  }

  private waitForSyncingToComplete(): Observable<boolean> {
    return this.syncing$.pipe(
      filter((syncing) => !syncing),
      take(1)
    );
  }
}
