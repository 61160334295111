import { Finding } from "../entities";

export class FindingService {
  public static exists(list: Finding[], finding: Finding): Finding {
    return list.find(
      (f) =>
        f.analysisId === finding.analysisId &&
        f.creatorId === finding.creatorId &&
        f.creatorEntity === finding.creatorEntity &&
        f.pipelineStepId === finding.pipelineStepId
    );
  }

  public static isFromAnalysisDuplicated(
    list: Finding[],
    finding: Finding
  ): Finding {
    return list.find(
      (f) =>
        f.analysisId !== finding.analysisId &&
        f.assetId === finding.assetId &&
        f.creatorId === finding.creatorId &&
        f.creatorEntity === finding.creatorEntity &&
        f.pipelineStepId === finding.pipelineStepId
    );
  }

  public static getAIRois(rois) {
    //Get only the maximum probability label for AI rois
    return rois.map((roi) => {
      let labels;
      const values = Object.values((roi.labels as any[]) || {});

      if (!values.length) labels = {};
      const maxProb = Math.max(...values);
      const indices = values.reduce((acc, val, index) => {
        if (val === maxProb) {
          acc.push(index);
        }
        return acc;
      }, []);
      if (indices.length > 0) {
        labels = indices.reduce((acc, index) => {
          acc[Object.keys(roi.labels)[index]] = maxProb;
          return acc;
        }, {});
      } else {
        labels = {};
      }
      return { ...roi, labels };
    });
  }
}
