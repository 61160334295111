import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '../services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private _auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      request.url.match(
        /\/v\d\/(files|export|ai|support-files|execute|rois|crops)/gi
      )
    ) {
      const token = this._auth.sessionToken;
      if (token) {
        request = request.clone({
          setHeaders: {
            "X-Parse-Session-Token": token,
          },
        });
      }
    }
    return next.handle(request);
  }
}

export const httpTokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true,
  deps: [AuthService],
};
