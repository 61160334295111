import { createFeatureSelector, createSelector } from "@ngrx/store";

import { ViewerContextState } from "./viewer-context.state";
import { Limits } from "./viewer-context.actions";

export const ViewerCtxFeatureKey = "viewerCtx";
export const selectViewerCtxFeature =
  createFeatureSelector<ViewerContextState>(ViewerCtxFeatureKey);

export const isAuthUserActive = (authUserId: string) =>
  createSelector(
    selectViewerCtxFeature,
    (state) => state?.sampleAnalysisState?.user.id === authUserId
  );

export const analysisState = createSelector(
  selectViewerCtxFeature,
  (state) => state?.sampleAnalysisState
);

export const caseAnalysisState = createSelector(
  selectViewerCtxFeature,
  (state) => state?.caseAnalysisState
);
export const selectActiveSample = createSelector(
  selectViewerCtxFeature,
  (state) => state?.selectedSample
);

export const progressPercent = createSelector(selectViewerCtxFeature, (state) =>
  state?.numAssets
    ? Math.floor((state?.refStripItems.length / state?.numAssets) * 100)
    : 0
);
export const selectRefStripElements = createSelector(
  selectViewerCtxFeature,
  (state) => state?.refStripItems
);
export const numAssets = createSelector(
  selectViewerCtxFeature,
  (state) => state?.refStripItems?.length
);

export const refStripItemsReady = createSelector(
  selectViewerCtxFeature,
  (state) => state?.refStripItems.length === state?.numAssets
);
export const selectAssetIndex = createSelector(
  selectViewerCtxFeature,
  (state) => state?.selectedAssetIndex
);
export const selectAssetAndIndex = createSelector(
  selectViewerCtxFeature,
  (state) => ({
    asset: state?.selectedAsset,
    index: state?.refStripItems.find(
      (item) => item.assetId === state?.selectedAsset?.id
    )?.assetIndex,
  })
);
export const selectAssetId = createSelector(
  selectViewerCtxFeature,
  (state) =>
    state.refStripItems.find(
      (item) => item?.assetIndex === state?.selectedAssetIndex
    )?.assetId
);
export const selectAsset = createSelector(
  selectViewerCtxFeature,
  (state) => state?.selectedAsset
);
export const isFav = createSelector(
  selectViewerCtxFeature,
  (state) =>
    state?.refStripItems.find(
      (item) => item.assetId === state?.selectedAsset?.id
    )?.favAnalysis ?? false
);
export const viewedAssets = createSelector(
  selectViewerCtxFeature,
  (state) =>
    state?.refStripItems.filter((item) => item.viewed)?.map((i) => i.assetId) ??
    []
);

export const isBookmarkedAsset$ = createSelector(
  selectViewerCtxFeature,
  (state) =>
    state?.sampleAnalysisState?.assetBookmark?.id ===
      state?.selectedAsset?.id ?? false
);
export const bookmarkedAssetIndex$ = createSelector(
  selectViewerCtxFeature,
  (state) =>
    state?.refStripItems.find(
      (item) => item.assetId === state?.sampleAnalysisState?.assetBookmark?.id
    )?.assetIndex ?? undefined
);
export const availableUserAnalysts$ = createSelector(
  selectViewerCtxFeature,
  (state) => state?.availableAnalysts?.filter((_analyst) => !_analyst.isAI)
);
export const availableAIAnalysts$ = createSelector(
  selectViewerCtxFeature,
  (state) => state?.availableAnalysts?.filter((_analyst) => _analyst.isAI)
);

export const selectedAnalyst$ = (isAI: boolean) =>
  createSelector(selectViewerCtxFeature, (state) =>
    state.availableAnalysts.find(
      (analyst) => analyst.selected && analyst.isAI === isAI
    )
  );

export const nextCase = createSelector(
  selectViewerCtxFeature,
  (state) => state?.nextCase
);
export const previousCase = createSelector(
  selectViewerCtxFeature,
  (state) => state?.previousCase
);
export const nextSample = createSelector(
  selectViewerCtxFeature,
  (state) => state?.nextSample
);
export const previousSample = createSelector(
  selectViewerCtxFeature,
  (state) => state?.previousSample
);

export const refStripItemsNotFetched = (limits: Limits) =>
  createSelector(selectViewerCtxFeature, (state) => {
    const itemssUnfetched = state?.refStripItems.filter(
      (i) =>
        !i.fetched &&
        (state.assetFilters.analyzed
          ? true
          : i.assetIndex >= limits?.start && i.assetIndex <= limits?.end)
    );

    return itemssUnfetched.map((item) => item.assetId);
  });

export const assetFilters = createSelector(
  selectViewerCtxFeature,
  (state) => state?.assetFilters
);

export const assetsFiltered = createSelector(
  selectViewerCtxFeature,
  (state) => {
    const assetFilters = Object.keys(state.assetFilters).filter(
      (f) => state.assetFilters[f] === true
    );
    const items =
      assetFilters.length > 0
        ? state?.refStripItems.filter((f) => {
            return assetFilters.every((filter) => f[filter]);
          })
        : state?.refStripItems;

    return items;
  }
);

export const viewerType = createSelector(
  selectViewerCtxFeature,
  (state) => state?.viewerType
);

export const mosaicMode = createSelector(
  selectViewerCtxFeature,
  (state) => state?.mosaics.mosaicMode
);

export const mosaicView = createSelector(
  selectViewerCtxFeature,
  (state) => state?.mosaics.mosaicView
);

export const multipleFiltersActive = createSelector(
  selectViewerCtxFeature,
  (state) => state?.multipleFiltersEnabled
);
