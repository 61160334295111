import { CommonModule } from "@angular/common";
import { Component, Inject, NgModule, Optional } from "@angular/core";
import { FormsModule } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogModule,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { TranslateModule } from "@ngx-translate/core";

import { Observable, of } from "rxjs";
import { ConfirmationDialogComponent } from "../confirm-dialog/confirm-dialog.component";

export interface SampleReviewDialogData {
  availableAnalysts: Observable<AnalystDetail[]>;
  sampleId: string;
  currUser: AnalystDetail;
}

export interface AnalystDetail {
  name: string;
  entity: string;
  id: string;
}
@Component({
  selector: "ts-sample-review-dialog",
  templateUrl: "./sample-review-dialog.component.html",
  styleUrls: ["./sample-review-dialog.component.scss"],
})
export class SampleReviewDialogComponent {
  public selectedAnalyst: any;

  constructor(
    @Optional() public dialogRef: MatDialogRef<SampleReviewDialogComponent>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: SampleReviewDialogData = {
      availableAnalysts: of([]),
      sampleId: undefined,
      currUser: undefined,
    }
  ) {}

  accept() {
    this.dialogRef.close(this.selectedAnalyst);
  }

  openDialog() {
    if (this.selectedAnalyst?.id === this.data.currUser.id)
      return this.accept();

    const confirmationDialogRef = this._dialog.open(
      ConfirmationDialogComponent,
      {
        data: {
          title: "",
          text: `dialog.sample_review`,
          params: { term: this.selectedAnalyst?.name },
          cancelButtonText: "button.cancel",
          acceptButtonText: "button.accept",
          defaultAction: "accept",
        },
      }
    );
    confirmationDialogRef.afterClosed().subscribe((result) => {
      if (result === "accept") {
        this.accept();
      } else {
        this.cancel();
      }
    });
  }

  cancel() {
    this.dialogRef?.close("cancel");
  }
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MatDialogModule,
    MatProgressBarModule,
    MatRadioModule,
    FormsModule,
  ],
  declarations: [SampleReviewDialogComponent],
  exports: [SampleReviewDialogComponent],
})
export class SampleReviewDialogComponentModule {}
