import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { EMPTY, of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import {
  clearSyncState,
  deleteSyncedItem,
  loadSyncedItems,
  syncActionError,
} from "./sync.actions";
import { SyncedItemType } from "./sync.reducer";
import { findingsFromSampleCopied } from "../cases";
import { findingsSynced, mosaicRoisLoaded } from "../analysis";
import { mosaicMode } from "../../+state";
import { StepTask } from "@telespot/sdk";

@Injectable()
export class SyncEffects {
  constructor(private actions$: Actions, private store$: Store) {}

  findingsCopied$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findingsFromSampleCopied),
      switchMap(({ findingDetails }) => {
        return of(findingDetails).pipe(
          map((_) =>
            loadSyncedItems({
              syncedItems: [
                ...findingDetails
                  .filter((f) => f.type === StepTask.POSITION)
                  .map((i) => ({
                    id: i.uuid,
                    version: i.version,
                    assetId: i.assetId,
                    type: SyncedItemType.FINDING,
                  })),
              ],
            })
          ),
          catchError((error) =>
            of(
              syncActionError({
                error: `[findingsFromSampleCopied]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  findingsSaved$ = createEffect(() =>
    this.actions$.pipe(
      ofType(findingsSynced),
      switchMap(({ idChanges }) => {
        return of(idChanges).pipe(
          map((_) =>
            loadSyncedItems({
              syncedItems: [
                ...idChanges
                  .filter((c) => c.type === StepTask.POSITION)
                  .filter((c) => !c.emptyData)
                  .map((i) => ({
                    id: i.uuid,
                    version: i.version,
                    assetId: i.assetId,
                    type: SyncedItemType.FINDING,
                  })),
              ],
            })
          ),
          catchError((error) =>
            of(
              syncActionError({
                error: `[findingsFromSampleCopied]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  clearSyncedInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mosaicRoisLoaded),
      switchMap(({ findings, lastBatch }) => {
        if (lastBatch) {
          return of(clearSyncState());
        }
        return of(
          deleteSyncedItem({ findingIds: findings.map((f) => f.uuid) })
        );
      })
    )
  );
}
