import type Parse from "parse";

import { Algorithm, ExecutionEnvironment } from "@telespot/domain";

import { ParseMapper } from "../../parse.mapper";
import { ObjectTopology } from "../../parse.topology";

export enum AlgorithmTopology {
  TABLE = "Algorithms",
  NAME = "name",
  VERSION = "version",
  DESCRIPTION = "description",
  REGULATED = "regulated",
  CLOUD_CONFIG = "cloud_config",
  MOBILE_CONFIG = "mobile_config",
  APPLICATION = "application",
}

export class ParseAlgorithmMapper extends ParseMapper<Algorithm> {
  public toDomain(parseObject: Parse.Object): Algorithm {
    const cloudConfig = parseObject.get(AlgorithmTopology.CLOUD_CONFIG);
    const mobileConfig = parseObject.get(AlgorithmTopology.MOBILE_CONFIG);

    const environment = cloudConfig
      ? ExecutionEnvironment.CLOUD
      : mobileConfig
        ? ExecutionEnvironment.MOBILE
        : undefined;

    // FIX: throw on server as validation in beforeSave
    if (!environment)
      throw new Error(
        "[ParseAlgorithmMapper] Could not determine execution environment"
      );

    return new Algorithm({
      id: parseObject.id,
      name: parseObject.get(AlgorithmTopology.NAME),
      version: parseObject.get(AlgorithmTopology.VERSION),
      description: parseObject.get(AlgorithmTopology.DESCRIPTION),
      regulated: Boolean(parseObject.get(AlgorithmTopology.REGULATED)),
      environment,
      config: cloudConfig ?? mobileConfig,
      application: parseObject.get(AlgorithmTopology.APPLICATION),
      createdAt: parseObject.get(ObjectTopology.CREATED_AT),
      updatedAt: parseObject.get(ObjectTopology.UPDATED_AT),
    });
  }

  public fromDomain(algorithm: Algorithm): Parse.Object {
    const configKey = algorithm.executableOn(ExecutionEnvironment.CLOUD)
      ? AlgorithmTopology.CLOUD_CONFIG
      : AlgorithmTopology.MOBILE_CONFIG;

    const parseObject = this.parse.Object.fromJSON({
      className: AlgorithmTopology.TABLE,
      [ObjectTopology.ID]: algorithm.id,
      [ObjectTopology.CREATED_AT]: algorithm.createdAt,
      [ObjectTopology.UPDATED_AT]: algorithm.updatedAt,
    });

    parseObject.set({
      [AlgorithmTopology.NAME]: algorithm.name,
      [AlgorithmTopology.VERSION]: algorithm.version,
      [AlgorithmTopology.DESCRIPTION]: algorithm.description,
      [AlgorithmTopology.REGULATED]: algorithm.regulated,
      [AlgorithmTopology.APPLICATION]: algorithm.application,
      [configKey]: algorithm.config,
    });

    return parseObject;
  }
}
