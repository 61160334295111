<div class="analysis-panel-mosaics custom-scrollbar">
  <div class="custom-mat-expansion-panel analysis-expansion-panel">
    <h3 class="panel-title">
      TOTAL SAMPLE OBJECT COUNT :
      <span style="font-weight: 600">{{ totalCount$ | async }}</span>
    </h3>
    <div
      class="analysis-type-expansion-panel"
      *ngFor="let group of assetProtocol$ | async"
      style="padding-top: 0px"
    >
      <h4 style="margin-bottom: 0px">
        {{ group.name }}
      </h4>
      <!-- <span style="font-weight: 600; padding-right: 40px">100</span> -->

      <div class="symbols-section">
        <span class="symbol" id="hash-symbol">#</span>
        <span class="symbol" id="percent-symbol">%</span>
      </div>
      <mat-expansion-panel
        *ngFor="let task of group.tasks; let i = index"
        class="custom-mat-expansion-panel analysis-type-expansion-panel"
        [expanded]="true"
        style="padding-top: 0px"
      >
        <mat-expansion-panel-header class="group-category-and-totals">
          <mat-panel-title class="flex-row justify-content-between">
            <h4 style="margin-bottom: 0px">
              {{ task.name }}
            </h4>
            <div class="totals">
              <span class="total">{{
                getTotalCounts(task.options).countTotal
              }}</span>
              <span class="total">{{
                getTotalCounts(task.options).percentageTotal | number : "1.0-1"
              }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="drop-list">
          <ng-container *ngFor="let label of task.options">
            <div
              class="drop-item"
              cdkDropList
              cdkDropListSortingDisabled="true"
              [id]="label.uuid"
              [cdkDropListConnectedTo]="connectedDropLists$ | async"
              (cdkDropListDropped)="drop($event)"
            >
              <span class="item-name">{{ label.name }}</span>
              <span
                class="item-icons"
                (click)="updateLabelsForCounter(label.uuid)"
                [matTooltip]="
                  label.removeFromCounter
                    ? ('tooltip.add_to_counter' | translate)
                    : ('tooltip.remove_from_counter' | translate)
                "
                ><i
                  [ngClass]="{
                    'ri-increase-decrease-fill': !label.removeFromCounter,
                    'ri-increase-decrease-line': label.removeFromCounter
                  }"
                ></i
              ></span>
              <span class="item-numbers">
                <span
                  class="number"
                  [ngStyle]="{
                    'background-color': label.color
                  }"
                  data-test-id="item-number-1"
                  ><span style="font-size: 0.8em">{{
                    (getLabelCount(label.uuid) | async)?.count
                  }}</span></span
                >
                <span
                  class="number"
                  [ngStyle]="{
                    'background-color': label.color
                  }"
                  data-test-id="item-number-2"
                  ><span style="font-size: 0.8em">{{
                    (getLabelCount(label.uuid) | async)?.percentage
                      | number : "1.0-1"
                  }}</span></span
                >
              </span>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel
        class="custom-mat-expansion-panel analysis-type-expansion-panel"
        [expanded]="false"
        style="padding-top: 0px"
      >
        <mat-expansion-panel-header class="group-category-and-totals">
          <mat-panel-title class="flex-row justify-content-between">
            <h4 style="margin-bottom: 0px">
              {{ "label.unlabeled" | translate }}
            </h4>
            <div class="totals">
              <span class="total">{{ unlabeledCount.count }}</span>
              <span class="total">{{ unlabeledCount.percentage }}</span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </div>
  </div>
</div>
