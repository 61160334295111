import { Dictionary } from "@ngrx/entity";
import {
  Algorithms,
  Analysis,
  AnalysisType,
  Asset,
  AssetStatus,
  Sample,
  User,
} from "@telespot/sdk";
import uid from "uid";
import { IAnalysis, Label, POI, ROI } from "../../state";

export class AnalysisMapper {
  public static fromStateAnalysis(
    analysis: IAnalysis,
    rois: (ROI | POI)[],
    labels: Dictionary<Label>
  ): Analysis {
    const parseAnalysis = analysis.id.includes("new:")
      ? new Analysis()
      : (Analysis.createWithoutData(
          analysis.id.startsWith("copy:")
            ? analysis.id.substring(analysis.id.indexOf("replace:") + 8)
            : analysis.id
        ) as Analysis);

    const isUser = analysis.createdBy.className === User.className;

    const analysedByKey = isUser ? "createdBy" : "algorithm";
    const analysedByPointer = (isUser ? User : Algorithms).createWithoutData(
      analysis.createdBy.objectId
    );

    if (analysis.assetId !== undefined)
      parseAnalysis.asset = Asset.createWithoutData(analysis.assetId);

    if (analysis.id.startsWith("copy:"))
      parseAnalysis.assetStatus = AssetStatus.reviewed;
    parseAnalysis.analysisType = AnalysisType.createWithoutData(
      analysis.analysisTypeId
    ) as AnalysisType;
    parseAnalysis.sample = Sample.createWithoutData(analysis.sampleId);
    parseAnalysis.data = {
      ...analysis.data,
      ...this.toDataRois(rois, analysis.id, labels), // REVIEW: or filter in selector
    };

    parseAnalysis.set(analysedByKey, analysedByPointer);

    return parseAnalysis;
  }

  public static toStateAnalysis(analysis: Analysis): IAnalysis {
    const roisTasks = analysis.analysisType.tasks.filter(
      (task) => task.roiSelection
    );

    const data = { ...(analysis.data ?? {}) };

    for (const task of roisTasks) {
      delete data[task.name];
      delete data[task.displayName];
    }

    return {
      id: analysis?.id,
      synced: true,
      createdBy: (analysis?.algorithm ?? analysis?.createdBy)?.toPointer(),
      data,
      sampleId: analysis?.sample?.id,
      assetId: analysis?.asset?.id,
      isSampleAnalysis: !analysis?.asset?.id,
      analysisTypeId: analysis?.analysisType?.id,
    };
  }

  public static getCustomLabels(analysis: Analysis): Label[] {
    if (!analysis) return [];

    const customLabels: Label[] = [];

    const tasks = analysis.analysisType.tasks.filter(
      (task) => task.roiSelection
    );

    for (const task of tasks) {
      const availableOptionsInTask = task.options?.map((o) => o.name) ?? [];
      const analysisResponses = analysis?.data[task.name] ?? {};

      const customOptions = Object.keys(analysisResponses).filter(
        (key) => !availableOptionsInTask.includes(key)
      );

      customOptions.forEach((optionName) =>
        customLabels.push({
          analysisTypeId: analysis.analysisType.id,
          category: task.name,
          value: optionName,
          selected: false,
          visible: true,
          pinned: false,
          removeFromCounter: false,
        })
      );
    }

    return customLabels;
  }

  public static extractCustomROIs(analysis: Analysis): ROI[] {
    if (!analysis) return [];
    const roisToAdd = [];

    const tasks = analysis.analysisType.tasks.filter(
      (task) => task.roiSelection
    );

    for (const task of tasks) {
      const availableOptionsInTask = task.options?.map((o) => o.name) ?? [];
      const analysisResponses = analysis?.data[task.name] ?? {};

      const customOptions = Object.keys(analysisResponses).filter(
        (key) => !availableOptionsInTask.includes(key)
      );

      customOptions.forEach((optionName) => {
        const analysisDataRois = analysis?.data[task.name]?.[optionName];

        if (!analysisDataRois) return;

        return roisToAdd.push(
          ...analysisDataRois.map((roiData) => ({
            x: roiData.x,
            y: roiData.y,
            w: roiData?.w ?? 0,
            h: roiData?.h ?? 0,
            ...(roiData?.t && {
              time: roiData?.t,
            }),
            id: uid(6),
            selected: false,
            isAIResult: false,
            labels: [
              {
                analysisId: analysis?.id,
                labels: [`category:${task.name}/value:${optionName}`],
              },
            ],
          }))
        );
      });
    }

    return roisToAdd;
  }

  public static extractProtocolROIs(analysis: Analysis): ROI[] {
    if (!analysis) return [];
    const roisToAdd = [];

    const tasks = analysis.analysisType.tasks.filter(
      (task) => task.roiSelection
    );

    for (const task of tasks) {
      task?.options?.forEach((option) => {
        const analysisDataRois =
          analysis?.data[task.name]?.[option.name] ??
          analysis?.data[task.displayName]?.[option.name];

        if (!analysisDataRois) return;

        return roisToAdd.push(
          ...analysisDataRois.map((roiData) => ({
            x: roiData.x,
            y: roiData.y,
            w: roiData?.w ?? 0,
            h: roiData?.h ?? 0,
            ...(roiData?.t && {
              time: roiData?.t,
            }),
            id: uid(6),
            selected: false,
            isAIResult: false,
            labels: [
              {
                analysisId: analysis?.id,
                labels: [`category:${task.name}/value:${option.name}`],
              },
            ],
          }))
        );
      });
    }

    return roisToAdd;
  }

  public static toDataRois(
    rois: (ROI | POI)[],
    analysisId: string,
    labels: Dictionary<Label>
  ) {
    return rois.reduce((result, roi) => {
      const labelIds =
        roi.labels.find((label) => label.analysisId === analysisId)?.labels ??
        [];

      const roiLabels = labelIds.map((id) => labels[id]);

      for (const { category, value } of roiLabels) {
        result[category] = {
          ...(result[category] ?? {}),
          [value]: [
            ...(result[category]?.[value] ?? []),
            {
              x: roi.x,
              y: roi.y,
              w: roi["w"],
              h: roi["h"],
              ...(roi?.time > 0 && {
                t: roi?.time,
              }),
            },
          ],
        };
      }

      return result;
    }, {});
  }
}
