<cdk-virtual-scroll-viewport
  style="width: 100%; display: block"
  orientation="horizontal"
  id="referenceStrip"
  *ngIf="{
    items: elements$ | async,
    selectedIndex: selectedIndex$ | async,
    bookmarkedIndex: bookmarkedAssetIndex$ | async
  } as state"
>
  <div
    class="stripThumbnail"
    *cdkVirtualFor="let item of state.items; let stripItemIndex = index"
    (click)="selectItem(item.assetIndex, item.assetId)"
    tsAnalytics="referenceStrip_change_image"
    [class.active]="state.selectedIndex === item.assetIndex"
    role="button"
    [class.highlight]="item.fav"
    [class.viewed]="item.viewed"
    [class.hidden]="item.hidden"
  >
    <div class="stripTitleBar">
      <div class="icons-bar horizontal">
        <span class="badge-index">
          <i
            class="ri-bookmark-fill"
            id="bookmark-icon"
            *ngIf="state.bookmarkedIndex === item.assetIndex"
          ></i>
          <span class="badge-index__index">{{ item.assetIndex + 1 }}</span>
        </span>

        <i
          class="ri-star-line badge-fav"
          *ngIf="item.fav"
          data-bs-toggle="tooltip"
          title="{{ 'tooltip.acquisition_favourite' | translate }}"
        >
        </i>
        <i
          class="ri-heart-3-line badge-fav-analysis"
          *ngIf="item.favAnalysis"
          data-bs-toggle="tooltip"
          title="{{ 'tooltip.analysis_favourite' | translate }}"
        >
        </i>
      </div>
      <i
        class="ri-shape-line badge-analyzed"
        [class.reviewed]="item.reviewed"
        *ngIf="item.analyzed"
        data-bs-toggle="tooltip"
        title="Analyzed"
      >
      </i>
      <i
        class="ri-shapes-line badge-large"
        *ngIf="item.largeObjects"
        title="Large Objects"
      >
      </i>
    </div>
    <img
      [src]="item.thumbnail | preprocess | async"
      alt="asset thumbnail {{ stripItemIndex + 1 }}"
      #thumbnail
    />
  </div>
</cdk-virtual-scroll-viewport>
