<ng-container *ngIf="groupedMosaics$ | async as groupedMosaics">
  <div *ngFor="let categoryObj of groupedMosaics">
    <h2 class="gallery-category">{{ categoryObj.category }}</h2>
    <div *ngFor="let label of objectKeys(categoryObj.labels)">
      <h3 *ngIf="label !== 'unlabeled'" class="gallery-label">{{ label }}</h3>
      <div
        *ngIf="categoryObj.labels[label].crops.length > 0"
        class="mosaic-gallery"
        cdkDropList
        [id]="label"
        [cdkDropListData]="categoryObj.labels[label].crops"
        [cdkDropListConnectedTo]="dragDropService.getConnectedDropLists()"
        (cdkDropListDropped)="onDrop($event)"
        cdkDropListSortingDisabled="true"
      >
        <div
          *ngFor="
            let cropInfo of categoryObj.labels[label].crops;
            let i = index
          "
          class="mosaic-item"
          [style.borderColor]="categoryObj.labels[label].color"
          (contextmenu)="openContextMenu($event, label, cropInfo)"
          (cdkDragStarted)="onDragStart()"
          cdkDrag
          [cdkDragData]="cropInfo"
        >
          <ng-container
            *ngIf="getCrop(cropInfo.roiID) as cropUrl; else otherSource"
          >
            <img
              [src]="cropUrl"
              alt="Image {{ i + 1 }} of {{ label }}"
              class="mosaic-image"
              loading="lazy"
            />
          </ng-container>

          <ng-template #otherSource>
            <img
              [src]="cropInfo.crop | async"
              alt="Image {{ i + 1 }} of {{ label }}"
              class="mosaic-image"
              loading="lazy"
            />
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="contextMenuVisible">
  <div
    class="context-menu"
    [style.top.px]="contextMenuPosition.y"
    [style.left.px]="contextMenuPosition.x"
    #contextMenu
  >
    <ul *ngFor="let category of assetProtocolTasks">
      <li class="category-item">
        {{ category.name }}
      </li>
      <li
        *ngFor="let label of category.options"
        class="label-item"
        (click)="onClick(label)"
      >
        <span
          *ngIf="selectedItemLabels.includes(label.name)"
          class="ri-check-line"
        ></span>
        {{ label.name }}
      </li>
    </ul>
  </div>
</ng-container>
