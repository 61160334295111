import type Parse from "parse";

import {
  Analysis,
  AnalysisRepository,
  FindAnalysisFilters,
  Finding,
} from "@telespot/domain";
import { AssetTopology, SampleTopology } from "../acquisition";
import { ParseBaseRepository } from "../../parse-base.repository";
import { AnalysisStateTopology } from "./parse-analysis-state.mapper";
import { AnalysisTopology, ParseAnalysisMapper } from "./parse-analysis.mapper";
import { AlgorithmTopology } from "../ai";
import { FindingTopology, ParseFindingMapper } from "./parse-findings.mapper";
import { ObjectTopology } from "../../parse.topology";
import { PipelineTopology } from "../protocols";

export class ParseAnalysisRepository
  extends ParseBaseRepository
  implements AnalysisRepository
{
  private readonly analysisMapper = new ParseAnalysisMapper(this.parse);
  private readonly findingMapper = new ParseFindingMapper(this.parse);

  public async saveAll(...analysis: Analysis[]): Promise<string[]> {
    const parseObjects = analysis.map((a) => this.analysisMapper.fromDomain(a));

    const results = await this.parse.Object.saveAll(parseObjects, this.options);

    return results.map((r) => r.id);
  }

  public async *forEachSampleAnalysis(
    ...sampleIds: string[]
  ): AsyncGenerator<Analysis> {
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);

    const parseSamples = sampleIds.map((id) =>
      ParseSample.createWithoutData(id)
    );

    const query = new this.parse.Query(AnalysisTopology.TABLE).containedIn(
      AnalysisStateTopology.SAMPLE,
      parseSamples
    );

    const generator = this.getGeneratorFromQuery(query);

    for await (const items of generator) {
      for (const item of items) yield this.analysisMapper.toDomain(item);
    }
  }

  public async findForSample(sampleId: string): Promise<Analysis[]> {
    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);

    const parseAnalysis = await new this.parse.Query(AnalysisTopology.TABLE)
      .equalTo(AnalysisTopology.SAMPLE, ParseSample.createWithoutData(sampleId))
      .find(this.options);

    return parseAnalysis.map((pa) => this.analysisMapper.toDomain(pa));
  }

  public async deleteAllAnalysis(analysisIds: string[]): Promise<void> {
    const ParseAnalysis = this.subclasses.getSubclass(AnalysisTopology.TABLE);

    const parseAnalysis = analysisIds.map((id) =>
      ParseAnalysis.createWithoutData(id)
    );

    await this.parse.Object.destroyAll(parseAnalysis, this.options);
  }

  public async *find(filters: FindAnalysisFilters): AsyncGenerator<Analysis[]> {
    const query = this.getQueryFrom(filters);

    const generator = this.getGeneratorFromQuery(query, 100, 0);

    for await (const items of generator)
      yield items.map((i) => this.analysisMapper.toDomain(i));
  }

  public async *forEach(
    filters: FindAnalysisFilters
  ): AsyncGenerator<Analysis> {
    for await (const batch of this.find(filters)) {
      for (const analysis of batch) yield analysis;
    }
  }

  public async *getFindings(
    filters: FindAnalysisFilters,
    creatorId: string
  ): AsyncGenerator<Finding[]> {
    for await (const batch of this.find(filters)) {
      const findingQuery = new this.parse.Query(FindingTopology.TABLE)
        .containedIn(
          FindingTopology.ANALYSIS,
          batch?.map((an) => this.analysisMapper.fromDomain(an))
        )
        .include(FindingTopology.ANALYSIS)
        .equalTo(FindingTopology.CREATOR_ID, creatorId)
        .descending(ObjectTopology.UPDATED_AT);

      const generator = this.getGeneratorFromQuery(findingQuery, 100, 0);

      for await (const items of generator)
        yield items.map((i) => this.findingMapper.toDomain(i));
    }
  }

  public async *findForAssets(
    assetIds: string[],
    filters: FindAnalysisFilters
  ): AsyncGenerator<Analysis[]> {
    const ParseAsset = this.subclasses.getSubclass(AssetTopology.TABLE);

    const assets = assetIds.map((id) => ParseAsset.createWithoutData(id));

    const query = this.getQueryFrom(filters).containedIn(
      AnalysisTopology.ASSET,
      assets
    );

    const generator = this.getGeneratorFromQuery(query, 100, 0);

    for await (const items of generator)
      yield items.map((i) => this.analysisMapper.toDomain(i));
  }

  public async save(analysis: Analysis): Promise<string> {
    const parseAnalysis = this.analysisMapper.fromDomain(analysis);

    const { id } = await parseAnalysis.save(null, this.options);

    return id;
  }

  private getQueryFrom(filters: FindAnalysisFilters): Parse.Query {
    const { sampleId, analyzerEntity, analyzerId, pipelineIds } = filters;

    const ParseSample = this.subclasses.getSubclass(SampleTopology.TABLE);
    const ParseAlgorithm = this.subclasses.getSubclass(AlgorithmTopology.TABLE);

    const sample = ParseSample.createWithoutData(sampleId);

    const isAI = analyzerEntity === "algorithm";
    const ParseAnalyzerEntity = isAI ? ParseAlgorithm : this.parse.User;

    const createdByKey = isAI ? "algorithm" : "createdBy";
    const createdBy = analyzerId
      ? ParseAnalyzerEntity.createWithoutData(analyzerId)
      : undefined;

    const favoriteInclude = [
      AnalysisTopology.ASSET,
      AssetTopology.FAVORITE,
    ].join(".");

    const query = new this.parse.Query(AnalysisTopology.TABLE)
      .equalTo(AnalysisTopology.SAMPLE, sample)
      .exists(AnalysisTopology.ASSET)
      .include(favoriteInclude)
      .descending(ObjectTopology.UPDATED_AT);

    if (createdBy) {
      query.equalTo(createdByKey, createdBy);
      query.exists(createdByKey);
    } else {
      query.doesNotExist(createdByKey);
    }

    if (pipelineIds) {
      const pipelinesQuery = new this.parse.Query(
        PipelineTopology.TABLE
      ).containedIn(ObjectTopology.ID, pipelineIds);
      query.matchesQuery(AnalysisTopology.PIPELINE, pipelinesQuery);
    }

    return query;
  }

  public async getByUUID(uuid: string): Promise<Analysis> {
    const parseAnalysis = await new this.parse.Query(AnalysisTopology.TABLE)
      .equalTo(AnalysisTopology.UUID, uuid)
      .first(this.options);

    return parseAnalysis
      ? this.analysisMapper.toDomain(parseAnalysis)
      : undefined;
  }
}
