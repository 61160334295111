import { createAction, props } from "@ngrx/store";
import { ISyncedItem } from "./sync.reducer";

export const syncActionError = createAction(
  "[SYNC] Action Error",
  props<{ error: string }>()
);

export const clearSyncState = createAction("[SYNC] Clear sync state");

export const loadSyncedItems = createAction(
  "[SYNC] Load synced items in state",
  props<{
    syncedItems: ISyncedItem[];
  }>()
);

export const deleteSyncedItem = createAction(
  "[SYNC] Delete synced items from the state",
  props<{
    findingIds: string[];
  }>()
);
