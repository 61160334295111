import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import {
  catchError,
  delay,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as AnalysisActions from "./analysis.actions";
import { EMPTY, forkJoin, from, of } from "rxjs";
import { Action, Store } from "@ngrx/store";
import { getROIsInsideRegion, IAnalysis } from "./analysis.reducer";
import {
  getRoiById,
  selectAnalysis,
  selectAssetsCropsProcessed,
  selectFindings,
  selectMode,
  selectRois,
  selectUnsyncedAnalysis,
  selectUnsyncedFindings,
} from "./analysis.selectors";
import { AnalysisService } from "../../services/analysis-service/analysis.service";
import { FindingService } from "../../services/finding-service/finding.service";
import {
  customLabelsAvailable,
  selectedLabels,
  selectHasSegmentationTasks,
  selectLabelsId,
  selectTaskGroups,
  selectTaskGroupsIds,
} from "../protocol/protocol.selectors";
import {
  activeAnalysisIds,
  findingUuidsWithoutCropsInfo,
  getActiveSegmentationFinding,
  selectActiveROIS,
  selectAssetROIsFromUser,
  selectCurrentAnalyst,
  unlabeledRois,
} from "../interfeature.selectors";
import {
  sampleAnalysisStateFetched,
  setAsset,
  toogleAnalysisMode,
} from "../../+state/viewer-context.actions";
import {
  analysisState,
  mosaicMode,
  selectActiveSample,
  selectAsset,
  selectRefStripElements,
} from "../../+state";
import { SampleAnalysisService } from "../../services/sample-analysis/sample-analysis.service";

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MaskViewerService } from "@telespot/shared/viewers/data-access";
import { MosaicService } from "@telespot/web-core";
import {
  SyncedItemType,
  loadSyncedItems,
  selectSyncedItemsByType,
} from "../sync";
import { findingsSyncedSaved } from "./analysis.actions";

@Injectable()
export class AnalysisEffects {
  loadAssetAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadAssetAnalysis),
      withLatestFrom(
        this.store$.select(selectAnalysis),
        this.store$.select(selectTaskGroupsIds),
        this.store$.select(mosaicMode),
        this.store$.select(selectLabelsId),
        this.store$.select(customLabelsAvailable)
      ),
      mergeMap(
        ([
          { assetId, sampleId, createdBy },
          cache,
          pipelineIds,
          isMosaicMode,
          labelIds,
          allowCustomLabels,
        ]) => {
          const cachedAnalysis = cache.filter(
            (analysis: IAnalysis) =>
              !analysis.isSampleAnalysis &&
              analysis.assetId === assetId &&
              analysis.createdBy.className === createdBy.className &&
              analysis.createdBy.objectId === createdBy.objectId
          );

          const fetchMissingFindings =
            cachedAnalysis.filter((a) => a.fetchedPartially).length !== 0;

          if (
            (cachedAnalysis.length > 0 && !fetchMissingFindings) ||
            isMosaicMode
          )
            return of(AnalysisActions.updateLoading({ loading: false }));

          const infoFetched = fetchMissingFindings
            ? this.analysisService.loadMissingFindings(
                cachedAnalysis.map((a) => a.id),
                labelIds,
                allowCustomLabels
              )
            : this.analysisService.loadAssetAnalysis({
                assetId,
                sampleId,
                createdBy,
                pipelineIds,
                labelIds,
                allowCustomLabels,
              });

          return infoFetched.pipe(
            map(({ analysis, findings, rois }) =>
              AnalysisActions.assetAnalysisLoaded({
                analysis,
                findings,
                rois,
              })
            ),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadAssetAnalysis]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  loadSampleAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadSampleAnalysis),
      withLatestFrom(
        this.store$.select(selectAnalysis),
        this.store$.select(selectTaskGroupsIds)
      ),
      mergeMap(([{ sampleId, createdBy }, cache, pipelineIds]) => {
        const cachedAnalysis = cache.filter(
          (analysis: IAnalysis) =>
            analysis.isSampleAnalysis &&
            analysis.createdBy.className === createdBy.className &&
            analysis.createdBy.objectId === createdBy.objectId &&
            analysis.sampleId === sampleId
        );

        if (cachedAnalysis.length > 0) return EMPTY;

        return this.analysisService
          .loadSampleAnalysis({ sampleId, createdBy, pipelineIds })
          .pipe(
            map(({ analysis, findings }) =>
              AnalysisActions.sampleAnalysisLoaded({ analysis, findings })
            ),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[loadSampleAnalysis]: ${error.message}`,
                })
              )
            )
          );
      })
    )
  );

  preSyncAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.preSyncAnalysis),
      withLatestFrom(
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(getActiveSegmentationFinding)
      ),
      mergeMap(([action, hasSegmentationTasks, activeSegmentationFinding]) => {
        if (hasSegmentationTasks && activeSegmentationFinding !== undefined) {
          this._maskViewerService.saveMaskOnLocalStorage.emit({
            save: true,
            id: activeSegmentationFinding.id,
          });
          return EMPTY;
        }
        return of(AnalysisActions.syncAnalysis());
      })
    )
  );

  syncAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.syncAnalysis),
      withLatestFrom(
        this.store$.select(selectUnsyncedAnalysis),
        this.store$.select(selectMode)
      ),
      mergeMap(([_, analysis, mode]) =>
        from(this.analysisService.saveAnalysis(analysis, mode)).pipe(
          map((idChanges) => {
            return AnalysisActions.analysisSynced({ idChanges });
          }),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[syncAnalysis]: ${error.message}`,
              })
            )
          )
        )
      )
    )
  );

  syncFindings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.analysisSynced),
      withLatestFrom(
        this.store$.select(selectUnsyncedFindings),
        this.store$.select(selectRois),
        this.store$.select(selectMode),
        this.store$.select(selectHasSegmentationTasks),
        this.store$.select(selectTaskGroups),
        this.store$.select(selectRefStripElements)
      ),
      mergeMap(
        ([
          { idChanges },
          findings,
          rois,
          mode,
          hasSegmentationTasks,
          protocols,
          assetsInfo,
        ]) =>
          from(
            this.findingService.saveFindings(
              findings,
              idChanges,
              rois,
              mode,
              hasSegmentationTasks,
              protocols,
              assetsInfo
            )
          ).pipe(
            map((idChanges) => {
              this._sampleAnalysisService.giveUserFeedback("Changes saved");
              return AnalysisActions.findingsSynced({ idChanges });
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[syncFindings]: ${error.message}`,
                })
              )
            )
          )
      )
    )
  );

  selectROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.selectROIsFromRegion),
      withLatestFrom(this.store$.select(selectRois)),
      mergeMap(([selectROIsReq, rois]) => {
        if (!selectROIsReq.bounds) return EMPTY;
        return of({
          rois: getROIsInsideRegion(
            rois,
            selectROIsReq.bounds,
            selectROIsReq.activeAnalysisIds
          ),
          replace: false,
        }).pipe(
          map(({ rois, replace }) =>
            AnalysisActions.setSelectedROIs({ rois, replace })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[selectROIsFromRegion]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  copyAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.copyAnalysis),
      mergeMap(
        ({ authUser }) =>
          of(authUser).pipe(
            withLatestFrom(
              this.store$.select(activeAnalysisIds),
              this.store$.select(selectActiveROIS),
              this.store$.select(selectAssetROIsFromUser(authUser))
            )
          ),
        (authUser, latestStoreData) => latestStoreData
      ),
      mergeMap(([authUser, activeAnalysisIds, newROIs, oldROIs]) => {
        return of({ authUser, activeAnalysisIds, newROIs, oldROIs });
      }),
      mergeMap(({ authUser, activeAnalysisIds, newROIs, oldROIs }) => [
        AnalysisActions.analysisCopied({ authUser, activeAnalysisIds }),
        AnalysisActions.setReviewCounters({ newROIs, oldROIs, authUser }),
      ]),
      catchError((error) =>
        of(AnalysisActions.analysisActionError({ error: error.message }))
      )
    )
  );

  deselectROIs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sampleAnalysisStateFetched, setAsset),
      mergeMap((_) => {
        return of(_).pipe(
          map((_) =>
            AnalysisActions.setSelectedROIs({ rois: [], replace: true })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[sampleAnalysisStateFetched, setAsset]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  createSegmAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.createSegmAnalysis),
      withLatestFrom(
        this.store$.select(analysisState),
        this.store$.select(selectAsset),
        this.store$.select(selectActiveSample),
        this.store$.select(selectedLabels)
      ),
      mergeMap(([action, analysisState, asset, sample, selectedLabels]) => {
        return of({
          createdBy: analysisState.user.toPointer(),
          assetId: asset?.id,
          sampleId: sample?.id,
          pipelineId: selectedLabels[0].pipelineId,
          taskId: selectedLabels[0].taskId,
        });
      }),
      map((payload) => AnalysisActions.segmAnalysisCreated(payload))
    )
  );

  clearStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.exitAnalysis),
      tap(() => {
        Object.keys(localStorage)
          .filter(
            (item) => item.startsWith("localMask/") || item.startsWith("crop_")
          )
          .forEach((item) => localStorage.removeItem(item));
      }),
      map(() => {
        return { type: "LocalStorageCleared" } as Action;
      })
    )
  );

  discardAnalysis$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.discardAnalysis),
      withLatestFrom(
        this.store$.select(selectAnalysis),
        this.store$.select(selectFindings),
        this.store$.select(selectHasSegmentationTasks)
      ),
      mergeMap(
        ([
          { analysisDiscarded, hasROI },
          allAnalysis,
          allFindings,
          hasSegmentationTasks,
        ]) => {
          const analysisToDiscard = analysisDiscarded
            .map((analysisInfo) => {
              return allAnalysis.find(
                (a) =>
                  a.pipelineId === analysisInfo.pipelineId &&
                  a?.createdBy.objectId === analysisInfo?.createdBy.objectId &&
                  a?.assetId === analysisInfo?.assetId &&
                  a?.sampleId === analysisInfo?.sampleId
              );
            })
            .filter((a) => a);

          const analysisDiscardedIds = analysisToDiscard.map((a) => a?.id);

          const findingsDiscardedIds = allFindings
            .filter((f) =>
              analysisDiscardedIds.some((an) => f.analysisId.includes(an))
            )
            .map((f) => f?.id);

          if (hasSegmentationTasks) {
            findingsDiscardedIds.map((id) =>
              localStorage.removeItem(`localMask/${id}`)
            );
          }

          return of({ analysisDiscardedIds, hasROI }).pipe(
            map(({ analysisDiscardedIds, hasROI }) => {
              return AnalysisActions.analysisDiscarded({
                analysisDiscardedIds,
                findingsDiscardedIds,
                hasROI,
              });
            }),
            catchError((error) =>
              of(
                AnalysisActions.analysisActionError({
                  error: `[discardAnalysis]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  loadMosaic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.loadMosaic),
      withLatestFrom(
        this.store$.select(selectCurrentAnalyst),
        this.store$.select(selectRefStripElements),
        this.store$.select(selectSyncedItemsByType(SyncedItemType.FINDING)),
        this.store$.select(selectAssetsCropsProcessed),
        this.store$.select(findingUuidsWithoutCropsInfo),
        this.store$.select(selectTaskGroupsIds)
      ),
      mergeMap(
        ([
          { nextToken, override },
          currAnalyst,
          refstripItems,
          syncedFindings,
          assetIdsProcessed,
          findingIdsWithoutCropInfo,
          pipelineIds,
        ]) => {
          const actions = [];

          const findingsToOverwrite = Array.from(
            new Set([
              ...syncedFindings.map((f) => f.id),
              ...findingIdsWithoutCropInfo.map((f) => f.uuid),
            ])
          );

          if (override) {
            actions.push(
              AnalysisActions.deleteObjects({
                findingUuids: findingsToOverwrite,
              })
            );
          }

          const assetsProcessed = assetIdsProcessed.filter(
            (id) => !syncedFindings.some((f) => f.assetId === id)
          );

          const assetToProcess = refstripItems.find(
            (item) => !assetsProcessed.includes(item.assetId)
          );

          return from(
            assetToProcess
              ? this._mosaicService.getRois(
                  "asset",
                  assetToProcess?.assetId,
                  "user",
                  currAnalyst.objectId,
                  [],
                  pipelineIds,
                  nextToken
                )
              : of({ items: [], nextToken: undefined })
          ).pipe(
            map((response) => {
              return {
                response,
                refstripItems,
                actions,
                syncedFindings,
                assetToProcess: assetToProcess?.assetId,
                assetsProcessed,
                prevToken: nextToken,
              };
            })
          );
        }
      ),
      mergeMap(
        ({
          response,
          refstripItems,
          actions,
          syncedFindings,
          assetToProcess,
          assetsProcessed,
          prevToken,
        }) => {
          const result = this.findingService.extractInfoFromMosaicRois(
            response?.items,
            refstripItems
          );

          return from(result).pipe(
            map((r) => {
              const processing = this.findingService.stillProcessing(
                syncedFindings,
                r?.findings
              );

              return {
                response,
                refstripItems,
                findings: r.findings,
                rois: r.rois,
                analysis: r.analysis,
                actions,
                processing,
                assetToProcess,
                assetsProcessed,
                prevToken,
              };
            })
          );
        }
      ),
      mergeMap(
        ({
          response,
          refstripItems,
          findings,
          rois,
          analysis,
          actions,
          processing,
          assetToProcess,
          assetsProcessed,
          prevToken,
        }) => {
          if (processing) {
            //Mosaic service still processing updates
            actions.push(
              AnalysisActions.loadMosaic({
                nextToken: prevToken,
                override: false,
                assetsProcessed: [],
              })
            );
          } else {
            actions.push(
              AnalysisActions.mosaicRoisLoaded({
                analysis,
                findings,
                rois,
                lastBatch: assetToProcess ? false : true,
              })
            );

            if (assetsProcessed.length !== refstripItems.length) {
              //Keep loading mosaic crops if there are still assets to process
              actions.push(
                AnalysisActions.loadMosaic({
                  nextToken: response?.nextToken,
                  override: false,
                  assetsProcessed: response?.nextToken
                    ? assetsProcessed
                    : [...assetsProcessed, assetToProcess],
                })
              );
            }
          }
          return actions.length ? from(actions).pipe(delay(500)) : EMPTY;
        }
      ),
      catchError((error) =>
        of(
          AnalysisActions.analysisActionError({
            error: `[loadMosaic]: ${error.message}`,
          })
        )
      )
    )
  );

  saveMosaicCropInStorage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.mosaicRoisLoaded),
      mergeMap(({ rois }) => {
        const cropBlobs$ = this.findingService.fetchCropBlobs(rois);
        return forkJoin(cropBlobs$).pipe(
          mergeMap((cropBlobs) => {
            cropBlobs.map((blob, index) => {
              localStorage.setItem(
                `crop_${rois[index].id}`,
                URL.createObjectURL(blob)
              );
            });
            return EMPTY;
          }),
          catchError((error) => {
            console.error("Error fetching or saving crop blob:", error);
            return of(
              AnalysisActions.analysisActionError({
                error: null,
              })
            );
          })
        );
      })
    )
  );

  dragCrop$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.dragCrop),
      mergeMap(({ roiId, previousLabels, newLabels }) =>
        of(roiId).pipe(
          withLatestFrom(this.store$.select(getRoiById(roiId))),
          map(([_, roi]) => ({ previousLabels, newLabels, roi }))
        )
      ),
      mergeMap(({ previousLabels, newLabels, roi }) => {
        if (!roi) return EMPTY;
        //Review: This suppose this roi has only labels for one findingId.
        const labelsUpdated = roi.labels.map((label) => {
          const updatedLabels = { ...label.labels };

          previousLabels.forEach((prevLabel) => {
            delete updatedLabels[prevLabel];
          });

          newLabels.forEach((newLabel) => {
            updatedLabels[newLabel] = 1; // Or any value you want
          });

          return {
            ...label,
            labels: updatedLabels,
          };
        });
        const updatedRoi = { ...roi, labels: labelsUpdated };

        return of(updatedRoi).pipe(
          map((updatedRoi) =>
            AnalysisActions.updateROI({
              roi: roi,
              changes: { labels: updatedRoi.labels },
            })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[dragCrop$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  changeCropLabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.changeCropLabels),
      mergeMap(({ roiId, findingId, newLabel }) =>
        of(roiId).pipe(
          withLatestFrom(this.store$.select(getRoiById(roiId))),
          map(([_, roi]) => ({ findingId, newLabel, roi }))
        )
      ),
      mergeMap(({ findingId, newLabel, roi }) => {
        if (!roi) return EMPTY;
        const labelsUpdated = roi.labels.map((label) => {
          if (label.findingId !== findingId) return label;
          const labelExists = Object.keys(label.labels).find(
            (l) => l === newLabel
          );
          const newLabels = labelExists
            ? Object.keys(label.labels).reduce((acc, l) => {
                if (l === newLabel) return acc;
                return { ...acc, [l]: label.labels[l] };
              }, {})
            : { ...label.labels, [newLabel]: 1 };

          return {
            ...label,
            labels: newLabels,
          };
        });
        const updatedRoi = { ...roi, labels: labelsUpdated };

        return of(updatedRoi).pipe(
          map((updatedRoi) =>
            AnalysisActions.updateROI({
              roi: roi,
              changes: { labels: updatedRoi.labels },
            })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[dragCrop$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  addLabel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AnalysisActions.addLabel),
      mergeMap(({ roiId, newLabel }) =>
        of(roiId).pipe(
          withLatestFrom(this.store$.select(getRoiById(roiId))),
          map(([_, roi]) => ({ newLabel, roi }))
        )
      ),
      mergeMap(({ newLabel, roi }) => {
        if (!roi) return;
        const labelsUpdated = roi.labels.map((label) => {
          const { ...labels } = label.labels;
          return {
            ...label,
            labels: {
              ...labels,
              [newLabel]: 1,
            },
          };
        });
        const updatedRoi = { ...roi, labels: labelsUpdated };

        return of(updatedRoi).pipe(
          map((updatedRoi) =>
            AnalysisActions.updateROI({
              roi: roi,
              changes: { labels: updatedRoi.labels },
            })
          ),
          catchError((error) =>
            of(
              AnalysisActions.analysisActionError({
                error: `[dragCrop$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  deleteUnlabeledRois$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toogleAnalysisMode),
      withLatestFrom(this.store$.select(unlabeledRois)),
      mergeMap(([action, unlabeledRois]) => {
        return of(AnalysisActions.deleteUnlabeledRois({ unlabeledRois }));
      })
    )
  );

  syncedItemsLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSyncedItems),
      map(() => findingsSyncedSaved())
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store,
    private analysisService: AnalysisService,
    private findingService: FindingService,
    private _sampleAnalysisService: SampleAnalysisService,
    private _maskViewerService: MaskViewerService,
    private _mosaicService: MosaicService
  ) {}
}
