import { Injectable } from "@angular/core";
import {
  DataService,
  FindingFileService,
  MosaicService,
  RoiItem,
} from "@telespot/web-core";
import {
  FindingChange,
  IFinding,
  ISyncedItem,
  Mode,
  POI,
  ProtocolSpecification,
  ROI,
} from "../../state";
import { FindingMapper } from "./finding.mapper";
import { CloudFunctions, Finding, StepTask } from "@telespot/sdk";
import { ISampleItem } from "../../models/i-sample-item";
import { AnalysisMapper } from "../analysis-service/analysis.mapper";
import { Observable } from "rxjs";
import { SafeUrl } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "any",
})
export class FindingService {
  constructor(
    private dataService: DataService,
    private _findingFileService: FindingFileService,
    private _mosaicService: MosaicService,
    private _http: HttpClient
  ) {}

  public async saveFindings(
    findings: IFinding[],
    changes: FindingChange<string>[],
    rois: (ROI | POI)[],
    mode: Mode,
    hasSegmentationTasks: boolean,
    protocols: ProtocolSpecification[],
    assetsInfo: ISampleItem[]
  ) {
    const isCopy = (f) => f.id.startsWith("copy:");

    if (mode === Mode.REVIEW) {
      const findingsUnsyncedIds = findings.map((f) =>
        isCopy(f) ? f.id.substring(f.id.indexOf("replace:")) : f.id
      );
      findings = findings.filter(
        (f) => !findingsUnsyncedIds.includes("replace:" + f.id)
      );
    }

    const parseFindings = findings.map((f) =>
      FindingMapper.fromStateFinding(f, changes, rois, protocols, assetsInfo)
    );

    const remoteFindings = await Finding.saveAll(parseFindings);

    let modifiedFindings = [];

    if (hasSegmentationTasks) {
      const segmentationFindings = remoteFindings.filter(
        (f) => f.type === StepTask.SEGMENTATION
      );
      if (segmentationFindings?.length) {
        modifiedFindings = await Promise.all(
          this.uploadMask(segmentationFindings, findings)
        );
        await Finding.saveAll(modifiedFindings);
      }
    }

    const newChanges = remoteFindings
      .map((ra, i) => ({
        previous: findings[i].id,
        current: ra.id,
        analysisId: ra.analysis.id,
        assetId: ra.analysis.asset?.id,
        version: ra.version,
        type: ra.type,
        uuid: findings[i].uuid,
        maskFileName: modifiedFindings.find((f) => f.id === ra.id)?.data,
        emptyData: ra.data?.content?.length ? false : true,
      }))
      .filter((change) => change.current !== change.previous);

    return newChanges;
  }

  public uploadMask(savedFindings: Finding[], unsyncedFindings: IFinding[]) {
    const segmentationFindings = savedFindings.map(async (f, i) => {
      const src = localStorage.getItem(`localMask/${unsyncedFindings[i].id}`);
      const imageBlob = this._findingFileService.dataURItoBlob(src);

      const file = new File([imageBlob], "mask.png", {
        type: "image/png",
      });
      const maskFileName = await this._findingFileService.upload(
        file,
        f.analysis.id,
        "files/findings"
      );
      localStorage.setItem(`localMask/${f.id}`, src);
      localStorage.removeItem(unsyncedFindings[i].id);

      f.data = { url: maskFileName };

      return f;
    });

    return segmentationFindings;
  }

  public async copyFindings(
    sampleId: string,
    currUserId: string,
    analyst: Parse.Pointer
  ) {
    return await CloudFunctions.copyFindingsFromSample(
      sampleId,
      currUserId,
      analyst
    );
  }

  public async extractInfoFromMosaicRois(
    items: RoiItem[],
    refStripItems: ISampleItem[]
  ) {
    const rois = FindingMapper.getStateRoisFromMosaicRois(items, refStripItems);
    const findings = FindingMapper.getFindingsFromMosaicRois(items);
    const analysis = AnalysisMapper.getAnalysisFromMosaicRois(items);

    return { findings, rois, analysis };
  }

  public stillProcessing(syncedFindings: ISyncedItem[], findingsFetched) {
    return findingsFetched.some((finding) =>
      syncedFindings.find(
        (f) => f.id === finding.uuid && finding.version < f.version
      )
    );
  }

  public fetchCropBlobs(rois): Observable<Blob>[] {
    return rois.map((roi) => {
      return this._mosaicService.getCrop(roi.cropFileName);
    });
  }
}
