import { Injectable } from "@angular/core";
import { DataService } from "@telespot/web-core";
import {
  Algorithms,
  Analysis,
  AnalysisState,
  AnalysisUtils,
  Asset,
  Case,
  Finding,
  Pipeline,
  Query,
  Sample,
  StepTask,
  User,
} from "@telespot/sdk";
import { from, Observable } from "rxjs";
import {
  AnalysisChange,
  IAnalysis,
  IFinding,
  Label,
  Mode,
  ROI,
} from "../../state";
import { AnalysisMapper } from "./analysis.mapper";
import { FindingMapper } from "../finding-service/finding.mapper";

@Injectable({
  providedIn: "any",
})
export class AnalysisService {
  constructor(private dataService: DataService) {}

  public async saveAnalysis(
    analysis: IAnalysis[],
    mode: Mode
  ): Promise<AnalysisChange<string>[]> {
    const copyPrefix = "copy:";
    const replacePrefix = "replace:";
    const analysingState = "inProgress";

    const isCopy = (an) => an.id.startsWith(copyPrefix);

    if (mode === Mode.REVIEW) {
      const analysisUnsyncedIds = analysis.map((an) =>
        isCopy(an) ? an.id.substring(an.id.indexOf(replacePrefix)) : an.id
      );
      analysis = analysis.filter(
        (an) => !analysisUnsyncedIds.includes(replacePrefix + an.id)
      );
    }

    const newAnalysis = analysis.filter((an) => an.id.includes("new:"));

    const parseAnalysis = newAnalysis.map((an) =>
      AnalysisMapper.fromStateAnalysis(an)
    );

    const remoteAnalysis = await Analysis.saveAll(parseAnalysis);

    const changes = remoteAnalysis
      .map((ra, i) => ({
        previous: newAnalysis[i].id,
        current: ra.id,
        assetId: ra?.asset?.id,
      }))
      .filter((change) => change.current !== change.previous);

    const analysisCasesIds = parseAnalysis.map((an) => an.sample?.case?.id);

    const uniqueCaseIds = Array.from(new Set(analysisCasesIds));

    const uniqueCasePointers = uniqueCaseIds.map((id) =>
      Case.createWithoutData(id)
    );

    const existingAnalysisStates = await new Query(AnalysisState)
      .equalTo("user", User.current())
      .containedIn("case", uniqueCasePointers)
      .find();

    if (!existingAnalysisStates.length) {
      const newAnalysisStates = uniqueCasePointers.map(
        (c) => new AnalysisState(User.current(), c, analysingState)
      );
      await AnalysisState.saveAll(newAnalysisStates);
    } else {
      existingAnalysisStates.forEach((as) => (as.state = analysingState));
      await AnalysisState.saveAll(existingAnalysisStates);
    }

    return changes;
  }

  public loadSampleAnalysis({ createdBy, sampleId, pipelineIds }): Observable<{
    analysis: IAnalysis[];
    findings: IFinding[];
  }> {
    const sample = Sample.createWithoutData(sampleId);
    const createdByKey =
      createdBy.className === "_User" ? "createdBy" : "algorithm";
    const createdType = createdBy.className === "_User" ? User : Algorithms;
    const createdByObject = createdType.createWithoutData(createdBy?.objectId);

    const pipelinesQuery = new Query(Pipeline).containedIn(
      "objectId",
      pipelineIds
    );

    const analysisQuery = new Query(Analysis)
      .equalTo("sample", sample)
      .equalTo(createdByKey, createdByObject)
      .exists(createdByKey)
      .matchesQuery("pipeline", pipelinesQuery)
      .exists("pipeline")
      .include(["pipeline"])
      .descending("createdAt")
      .doesNotExist("asset");

    return from(
      this.dataService
        .find(analysisQuery)
        // Filter analysis with same analysis type, since they are ordered by creation, only the latest will be mapped
        .then((analysis) =>
          analysis.filter(
            (an, i, array) =>
              array.findIndex((v) => v.pipeline.id === an.pipeline.id) === i
          )
        )
        .then((analysis) => {
          const batchSize = 100;
          let skip = 0;
          const findingsFetched = [];

          const fetchNextBatch = (): Promise<any> => {
            const findingsQuery = new Query(Finding)
              .containedIn("analysis", analysis)
              .equalTo("creatorId", createdBy?.objectId)
              .include(["pipelineStep"])
              .descending("version")
              .skip(skip)
              .limit(batchSize);

            return this.dataService.find(findingsQuery).then((findings) => {
              if (findings.length > 0) {
                findingsFetched.push(...findings);
                skip += batchSize;
                return fetchNextBatch();
              } else {
                return Promise.resolve({
                  analysis,
                  findingsFetched,
                });
              }
            });
          };

          return fetchNextBatch().then(({ analysis, findingsFetched }) => {
            const lastUpdatedFindings =
              this.getHighestVersionFindings(findingsFetched);
            return {
              analysis: analysis.map((an) =>
                AnalysisMapper.toStateAnalysis(an)
              ),
              findings: lastUpdatedFindings.map((f) =>
                FindingMapper.toStateFindings(f)
              ),
            };
          });
        })
    );
  }

  public loadAssetAnalysis({
    assetId,
    createdBy,
    sampleId,
    pipelineIds,
    labelIds,
    allowCustomLabels,
  }): Observable<{
    analysis: IAnalysis[];
    findings: IFinding[];
    rois: ROI[];
  }> {
    const sample = Sample.createWithoutData(sampleId);
    const createdByKey =
      createdBy.className === "_User" ? "createdBy" : "algorithm";
    const createdType = createdBy.className === "_User" ? User : Algorithms;
    const createdByObject = createdType.createWithoutData(createdBy?.objectId);
    const asset = Asset.createWithoutData(assetId);

    const pipelinesQuery = new Query(Pipeline).containedIn(
      "objectId",
      pipelineIds
    );

    const analysisQuery = new Query(Analysis)
      .equalTo("sample", sample)
      .matchesQuery("pipeline", pipelinesQuery)
      .exists("pipeline")
      .include(["pipeline", "analysis.asset"])
      .equalTo("asset", asset);

    if (createdType === Algorithms) {
      analysisQuery.doesNotExist("createdBy");
    } else {
      analysisQuery.equalTo(createdByKey, createdByObject).exists(createdByKey);
    }

    const batchSize = 100;
    let skip = 0;
    const findingsFetched = [];

    const fetchNextBatch = (): Promise<any> => {
      const findingsQuery = new Query(Finding)
        .matchesQuery("analysis", analysisQuery)
        .equalTo("creatorId", createdBy?.objectId)
        .include(["pipelineStep", "analysis.asset", "analysis.pipeline"])
        .descending("version")
        .skip(skip)
        .limit(batchSize);

      return this.dataService.find(findingsQuery).then((findings) => {
        if (findings.length > 0) {
          findingsFetched.push(...findings);
          skip += batchSize;
          return fetchNextBatch();
        } else {
          return Promise.resolve({ findingsFetched });
        }
      });
    };

    return from(
      fetchNextBatch().then(({ findingsFetched }) => {
        const lastUpdatedFindings =
          this.getHighestVersionFindings(findingsFetched);

        const result = this.filterAnalysisAndFindings(lastUpdatedFindings);

        return {
          analysis: result.filteredAnalysis.map((an) =>
            AnalysisMapper.toStateAnalysis(
              an,
              result.filteredFindings.find((f) => f.analysis.id === an.id)
            )
          ),
          findings: result.filteredFindings.map((f) =>
            FindingMapper.toStateFindings(f)
          ),
          rois: AnalysisUtils.flatten(
            result.filteredFindings.map((f) => [
              ...AnalysisMapper.extractFindingROIs(
                f,
                labelIds,
                allowCustomLabels
              ),
            ])
          ),
        };
      })
    );
  }

  public filterAnalysisAndFindings(findings: any[]) {
    const analysis = findings
      .flatMap((finding) => finding.analysis)
      .reduce((acc: Analysis[], analysis) => {
        if (!acc.find((item) => item.id === analysis.id)) {
          acc.push(analysis);
        }
        return acc;
      }, []);

    const filteredAnalysis = analysis
      .sort((a1, a2) => {
        const d1 = new Date(a1.updatedAt).getTime();
        const d2 = new Date(a2.updatedAt).getTime();
        return d2 - d1;
      })
      .filter(
        (an, i, array) =>
          array.findIndex((v) => v.pipeline.id === an.pipeline.id) === i
      );

    const filteredAnalysisIds = filteredAnalysis.map((a) => a.id);
    const filteredFindings = findings.filter((f) =>
      filteredAnalysisIds.includes(f.analysis.id)
    );
    return { filteredAnalysis, filteredFindings };
  }

  public loadMissingFindings(
    analysisIds: string[],
    labelIds,
    allowCustomLabels
  ) {
    const analysis = analysisIds.map(
      (id) => Analysis.createWithoutData(id) as Analysis
    );

    const batchSize = 100;
    let skip = 0;
    const findingsFetched = [];

    const fetchNextBatch = (): Promise<any> => {
      const findingsQuery = new Query(Finding)
        .containedIn("analysis", analysis)
        .notEqualTo("type", StepTask.POSITION)
        .descending("version")
        .skip(skip)
        .limit(batchSize);

      return this.dataService.find(findingsQuery).then((findings) => {
        if (findings.length > 0) {
          findingsFetched.push(...findings);
          skip += batchSize;
          return fetchNextBatch();
        } else {
          return Promise.resolve({ findingsFetched });
        }
      });
    };

    return from(
      fetchNextBatch().then(({ findingsFetched }) => {
        const lastUpdatedFindings =
          this.getHighestVersionFindings(findingsFetched);

        return {
          analysis: [],
          findings: lastUpdatedFindings.map((f) =>
            FindingMapper.toStateFindings(f)
          ),
          rois: AnalysisUtils.flatten(
            lastUpdatedFindings.map((f) => [
              ...AnalysisMapper.extractFindingROIs(
                f,
                labelIds,
                allowCustomLabels
              ),
            ])
          ),
        };
      })
    );
  }

  public getHighestVersionFindings(findings: Finding[]) {
    const highestVersionMap = new Map();

    findings.forEach((finding) => {
      const analysisId = finding.analysis.id;
      const pipelineStepId = finding.pipelineStep.id;

      const key = `${analysisId}-${pipelineStepId}`;

      if (
        !highestVersionMap.has(key) ||
        finding.version > highestVersionMap.get(key).version
      ) {
        highestVersionMap.set(key, finding);
      }
    });

    const uniqueFindings = Array.from(highestVersionMap.values());

    return uniqueFindings;
  }
}
