import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";
import {
  AnalysisReviewService,
  analysisState,
  availableUserAnalysts$,
  IAnalystData,
  isAnalystCurrentUser,
  Mode,
  SampleAnalysisService,
  selectedAnalyst$,
  selectHasUnsavedChanges,
  selectLoadingAnalysis,
  selectMode,
  switchMode,
} from "@telespot/analysis-refactor/data-access";
import { AnalysisState, keyName } from "@telespot/sdk";
import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, take, takeUntil } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { MatDialog } from "@angular/material/dialog";
import { UnsavedChangesDialogComponent } from "../dialogs/unsaved-changes-dialog/unsaved-changes-dialog.component";
import {
  TOsdActiveAction,
  ViewerService,
} from "@telespot/shared/viewers/data-access";

@Component({
  selector: "ts-analyst-selector",
  templateUrl: "./analyst-selector.component.html",
  styleUrls: ["./analyst-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AnalysisReviewService],
})
export class AnalystSelectorComponent {
  // Public members
  public readonly analystStates$: Observable<IAnalystData[]> =
    this.store.select(availableUserAnalysts$);

  public readonly value$ = this.store.select(selectedAnalyst$(false));
  public readonly editMode$ = this.store.select(isAnalystCurrentUser);
  public readonly loading$ = this.store.select(selectLoadingAnalysis);
  public readonly hasUnsavedData$ = this.store.select(selectHasUnsavedChanges);
  public readonly currentMode$ = this.store.select(selectMode);

  private _destroy$ = new Subject();

  private _currentUserAnalysisState: AnalysisState;
  private hasUnsavedData: boolean;
  private currentMode: Mode;

  constructor(
    private store: Store,
    private _analysisService: SampleAnalysisService,
    private analysisReviewService: AnalysisReviewService,
    private _dialog: MatDialog,
    private _viewerService: ViewerService
  ) {
    this.analystStates$
      .pipe(takeUntil(this._destroy$))
      .subscribe(
        (states) =>
          (this._currentUserAnalysisState = states.find(
            (state) => state?.isCurrentUser
          ).analysisState)
      );

    this.hasUnsavedData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((hasUnsavedData) => (this.hasUnsavedData = hasUnsavedData));

    this.currentMode$
      .pipe(takeUntil(this._destroy$))
      .subscribe((mode) => (this.currentMode = mode));

    document.addEventListener(
      "keydown",
      (e) => {
        if (
          (e.target as any).id === "selectAnalyst" &&
          Object.values(keyName).includes(e.key)
        ) {
          e.stopImmediatePropagation();
          switch (e.key) {
            case keyName.RIGHT_ARROW:
              this._analysisService.nextAsset();
              break;
            case keyName.LEFT_ARROW:
              this._analysisService.previousAsset();
              break;
          }
        }
      },
      true
    );
  }
  ngOnDestroy() {
    this._destroy$.next();
  }

  /**
   * Trigger user analysis change when switch analyst
   * @param event emmited when the slection of mat-select form change
   */
  changeUser(event: MatSelectChange) {
    const state: IAnalystData = event.value;
    const isOwn: boolean = !state || state?.isCurrentUser;
    if (this.hasUnsavedData && this.currentMode === Mode.REVIEW) {
      this._dialog
        .open(UnsavedChangesDialogComponent, {
          data: { username: state.analysisState.user.username },
        })
        .afterClosed()
        .subscribe((discard) => {
          if (!discard) return;
          this.analysisReviewService.navigateToUserAnalysis(isOwn, state);
          isOwn
            ? this._viewerService.toggleViewerMode(TOsdActiveAction.drawing)
            : this._viewerService.toggleViewerMode(TOsdActiveAction.idle);
          // deactivate review mode
          this.store
            .select(analysisState)
            .pipe(
              filter(
                (analysisState) => analysisState.id === state.analysisState.id
              ),
              take(1),
              distinctUntilChanged()
            )
            .subscribe((_) =>
              this.store.dispatch(switchMode({ mode: Mode.ANALYSIS }))
            );
        });
    } else {
      this.analysisReviewService.navigateToUserAnalysis(isOwn, state);
      isOwn
        ? this._viewerService.toggleViewerMode(TOsdActiveAction.drawing)
        : this._viewerService.toggleViewerMode(TOsdActiveAction.idle);
    }
  }

  public readonly byId = (id1, id2) =>
    id1?.analysisState.id === id2?.analysisState.id;

  toggleReferenceAnalysis(): void {
    if (!this._currentUserAnalysisState) return;
    if (this._currentUserAnalysisState.data?.reference) {
      this.analysisReviewService.unmarkAsReferenceAnalysis(
        this._currentUserAnalysisState
      );
    } else {
      this.analysisReviewService.markAsReferenceAnalysis(
        this._currentUserAnalysisState
      );
    }
  }
}
