import { createEntityAdapter, EntityState } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";

import * as SyncActions from "./sync.actions";

export enum SyncedItemType {
  FINDING = "finding",
}
export interface ISyncedItem {
  readonly id: string;
  version: number;
  type: SyncedItemType;
  assetId?: string;
}

export const syncAdapter = createEntityAdapter<ISyncedItem>({
  selectId: (item) => item.id,
});
export interface SyncState {
  error: string | null;
  syncedItems: EntityState<ISyncedItem>;
}

export const initialSyncState: SyncState = {
  error: null,
  syncedItems: syncAdapter.getInitialState(),
};

export function getSyncedItems(state: SyncState) {
  return Object.values(state.syncedItems.entities ?? {});
}

export const syncReducer = createReducer(
  initialSyncState,
  on(SyncActions.syncActionError, (state, { error }) => ({
    ...state,
    error,
  })),
  on(SyncActions.loadSyncedItems, (state, { syncedItems }) => {
    if (!syncedItems) return { ...state };
    return {
      ...state,
      syncedItems: syncAdapter.upsertMany(syncedItems, state.syncedItems),
    };
  }),
  on(SyncActions.clearSyncState, (state) => ({
    ...initialSyncState,
  })),
  on(SyncActions.deleteSyncedItem, (state, { findingIds }) => {
    if (!findingIds) return { ...state };
    return {
      ...state,
      syncedItems: syncAdapter.removeMany(findingIds, state.syncedItems),
    };
  })
);
