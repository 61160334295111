<div class="category-selector-container">
  <i class="ri-search-line" style="margin-right: 10px"></i>
  <mat-form-field style="width: 30vw" id="category-selector">
    <mat-label>{{ "button.filter_by_label" | translate }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="labelControl"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="onLabelChange($event.option.value)"
    >
      <ng-container *ngIf="categories$ | async as categories">
        <mat-optgroup *ngFor="let category of categories" [label]="category">
          <ng-container *ngIf="filteredLabels$ | async as labels">
            <mat-option
              *ngFor="let label of getLabels(labels, category)"
              [value]="label"
              [disabled]="disabledLabelOption(label)"
            >
              {{ label }}
            </mat-option>
          </ng-container>
        </mat-optgroup>
      </ng-container>
    </mat-autocomplete>
  </mat-form-field>
</div>
<div class="category-selector-container">
  <ng-container *ngFor="let sl of selectedLabels; let i = index">
    <span class="roi-badge">
      <span
        class="roi-badge__label"
        [style.borderColor]="getLabelColor(sl)"
        [style.color]="getLabelColor(sl)"
        >{{ sl }}
        <i
          class="ri-close-fill roi-badge__close-icon"
          (click)="removeLabelFilter(i)"
        ></i>
      </span>
    </span>
  </ng-container>
</div>
<div class="category-selector-container">
  <ng-container *ngIf="selectedLabels.length > 1">
    <span>
      <i class="ri-error-warning-line text-warning"></i>
      {{ "alert.drag_and_drop_disabled" | translate }}
    </span>
  </ng-container>
</div>
<div
  class="mosaic-gallery"
  cdkDropList
  [id]="containerId"
  [cdkDropListConnectedTo]="connectedDropLists"
  (cdkDropListDropped)="onDrop($event)"
  (cdkDropListEntered)="onDragEnter()"
  cdkDropListSortingDisabled="true"
>
  <ng-container *ngIf="selectedLabels.length >= 1">
    <ng-container *ngIf="roisObs$ | async as rois">
      <div *ngFor="let roi of rois; let i = index">
        <div
          class="mosaic-item"
          *ngIf="showCrop(roi.labels)"
          [style.borderColor]="getColor()"
          (contextmenu)="openContextMenu($event, roi)"
          (cdkDragStarted)="onDragStart()"
          cdkDrag
          [cdkDragData]="{ roiID: roi.id }"
        >
          <ng-container *ngIf="getCrop(roi.id) as cropUrl; else otherSource">
            <img
              [src]="cropUrl"
              class="mosaic-image"
              loading="lazy"
              alt="Image {{ i + 1 }} of {{ getAlternativeText() }}"
            />
          </ng-container>

          <ng-template #otherSource>
            <img
              [src]="getSafeUrl(roi.cropFileName) | async"
              alt="Image {{ i + 1 }} of {{ getAlternativeText() }}"
              class="mosaic-image"
              loading="lazy"
            />
          </ng-template>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="contextMenuVisible">
  <div
    class="context-menu"
    [style.top.px]="contextMenuPosition.y"
    [style.left.px]="contextMenuPosition.x"
    #contextMenu
  >
    <ul *ngFor="let category of assetProtocolTasks">
      <li class="category-item">
        {{ category.name }}
      </li>
      <li
        *ngFor="let label of category.options"
        class="label-item"
        (click)="onClick(label)"
      >
        <span
          *ngIf="selectedItemLabels.includes(label.name)"
          class="ri-check-line"
        ></span>
        {{ label.name }}
      </li>
    </ul>
  </div>
</ng-container>
