import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DragDropService {
  private dropListIds = new Set<string>();
  private _dropListIdsSubject$ = new Subject<string[]>();
  public dropListIdsObservable$ = this._dropListIdsSubject$.asObservable();

  addDropListId(id: string): void {
    this.dropListIds.add(id);
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  removeDropListId(id: string): void {
    this.dropListIds.delete(id);
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  clearAll(): void {
    this.dropListIds.clear();
    this._dropListIdsSubject$.next(this.getConnectedDropLists());
  }

  getConnectedDropLists(): string[] {
    return Array.from(this.dropListIds);
  }
}
