import { AnalysisType, Analysis } from "../entities";

export enum AnalysisValidationError {
  INCORRECT_ANALYSIS_TYPE = "Analysis created with different analysis type",
  UNSUPPORTED_TASK_OPTION = "Analysis data value for task has unknown option value specified",
}

export class AnalysisValidator {
  /**
   * Checks if a given analysis conforms to the provided type
   * @param analysis {@link Analysis} object to validate
   * @param analysisType {@link AnalysisType} to validate against
   */
  public static validate(analysis: Analysis, analysisType: AnalysisType) {
    if (analysis.analysisType.id !== analysisType.id)
      throw new Error(AnalysisValidationError.INCORRECT_ANALYSIS_TYPE);

    for (const dataKey of Object.keys(analysis.data)) {
      const taskForDataKey = analysisType.tasks.find(
        (task) => task.name === dataKey || task.displayName === dataKey
      );

      if (!taskForDataKey) continue;

      // REVIEW: verify that data value type in analysis is valid (if possible)

      if (!taskForDataKey.options?.length) continue;

      // REVIEW: validate if roiSelection
      if (taskForDataKey.roiSelection) continue;

      for (const optionKey of Object.keys(analysis.data?.[dataKey])) {
        const taskOptionForKey = taskForDataKey.options.find(
          (option) => option.name === optionKey
        );

        if (!taskOptionForKey)
          throw new Error(AnalysisValidationError.UNSUPPORTED_TASK_OPTION);
      }
    }
  }
}
