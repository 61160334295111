import { createSelector } from "@ngrx/store";
import { AnalysisUtils } from "@telespot/sdk";
import { User } from "parse";
import {
  analysisState,
  mosaicMode,
  numAssets,
  refStripItemsReady,
  selectActiveSample,
  selectAsset,
} from "../+state/viewer-context.selectors";
import {
  getActiveCopiedAnalysis,
  getLabelAnalysisIds,
  labelsToAnalysisLabelFormat,
  Mode,
} from "./analysis/analysis.reducer";
import {
  selectAnalysis,
  selectAssetsCropsProcessed,
  selectFindings,
  selectMode,
  selectMosaicLoading,
  selectRois,
} from "./analysis/analysis.selectors";
import {
  Context,
  getAllPossibleCounterFilters,
} from "./protocol/protocol.reducer";
import {
  disabledLabelsForCounter,
  labelsWithThresholds,
  selectContext,
  selectedLabels,
  selectTaskGroupsForActiveContext,
  selectTaskGroupsWithROITasks,
  selectVisibleLabels,
} from "./protocol/protocol.selectors";
import {
  SampleCounterState,
  selectActiveLabelCountReview,
  selectActiveMultiLabelReviewCount,
  selectActiveReviewCount,
  selectActiveSingleLabelReviewCount,
  selectSampleCounterFeature,
} from "./sample-counters";
import { selectAssetsFromSyncedFindings } from "./sync";

export const selectCurrentAnalyst = createSelector(analysisState, (history) =>
  (history?.user ?? history?.algorithm ?? User.current())?.toPointer()
);

export const isAnalystCurrentUser = createSelector(
  selectCurrentAnalyst,
  (currentAnalyst) =>
    currentAnalyst.className === User.current().className &&
    currentAnalyst.objectId === User.current().id
);

export const selectAnalysisForActiveTaskGroups = createSelector(
  selectTaskGroupsForActiveContext,
  selectAnalysis,
  (taskGroups, analysis) =>
    analysis.filter((an) =>
      taskGroups.map((tg) => tg.id).includes(an.pipelineId)
    )
);

export const selectActiveAnalysis = createSelector(
  selectAnalysisForActiveTaskGroups,
  selectCurrentAnalyst,
  selectContext,
  selectActiveSample,
  selectAsset,
  selectMode,
  (analysis, analyst, context, sample, asset, mode) => {
    let activeAnalysis = analysis.filter(
      (an) =>
        an.createdBy.className === analyst?.className &&
        an.createdBy.objectId === analyst?.objectId &&
        an.sampleId === sample?.id &&
        an.assetId === (context === Context.ASSET ? asset?.id : undefined)
    );
    if (mode === Mode.REVIEW)
      activeAnalysis = getActiveCopiedAnalysis(activeAnalysis);
    return activeAnalysis;
  }
);

export const selectActiveAssetContextAnalysis = createSelector(
  selectAnalysis,
  selectCurrentAnalyst,
  selectActiveSample,
  selectAsset,
  selectMode,
  (analysis, analyst, sample, asset, mode) => {
    let activeAnalysis = analysis.filter(
      (an) =>
        an.createdBy.className === analyst?.className &&
        an.createdBy.objectId === analyst?.objectId &&
        an.assetId === asset?.id &&
        an.isSampleAnalysis === false
    );
    if (mode === Mode.REVIEW)
      activeAnalysis = getActiveCopiedAnalysis(activeAnalysis);

    return {
      analysisRequest: {
        createdBy: analyst,
        sampleId: sample?.id,
        assetId: asset?.id,
      },
      analysisArray: activeAnalysis,
    };
  }
);

export const hasActiveAssetAnalysis = createSelector(
  selectActiveAssetContextAnalysis,
  (activeAssetAnalysis) => activeAssetAnalysis.analysisArray.length > 0
);

export const getActiveSegmentationFinding = createSelector(
  selectActiveAssetContextAnalysis,
  selectFindings,
  (activeAssetAnalysis, findings) =>
    findings.find(
      (f) => f.analysisId === activeAssetAnalysis.analysisArray[0]?.id
    )
);

export const hasCopyAnalysis = createSelector(
  selectActiveAssetContextAnalysis,
  selectAnalysis,
  (activeAssetAnalysis, allAnalysis) =>
    activeAssetAnalysis.analysisArray.length > 0 &&
    !allAnalysis.some((analysis) =>
      analysis?.id?.startsWith(
        "copy:" + activeAssetAnalysis.analysisArray[0].id
      )
    )
);

export const selectActiveAnalysisForTaskGroup = (taskGroupId: string) =>
  createSelector(selectActiveAnalysis, (analysis) =>
    analysis.find((an) => an.pipelineId === taskGroupId)
  );

export const selectFindingsForActiveAnalysis = (taskGroupId: string) =>
  createSelector(
    selectFindings,
    selectActiveAnalysisForTaskGroup(taskGroupId),
    (findings, analysis) =>
      findings.filter((f) => f.analysisId === analysis?.id)
  );

export const activeAnalysisIds = createSelector(
  selectActiveAssetContextAnalysis,
  (activeAnalysisInfo) =>
    activeAnalysisInfo.analysisArray.map((analysis) => analysis.id)
);
export const selectActiveAssetFindings = createSelector(
  activeAnalysisIds,
  selectFindings,
  (activeAnalysisIds, findings) =>
    findings.filter((f) => activeAnalysisIds.includes(f?.analysisId))
);
export const selectActiveROIS = createSelector(
  selectRois,
  activeAnalysisIds,
  (rois, activeAnalysisIds) =>
    rois.filter((roi) =>
      getLabelAnalysisIds([roi]).every((analysisId) =>
        activeAnalysisIds.includes(analysisId)
      )
    )
);

export const selectAssetAnalysisFromUser = (user: Parse.Pointer) =>
  createSelector(selectAnalysis, selectAsset, (analysis, asset) =>
    analysis
      .filter(
        (a) =>
          a.assetId === asset.id &&
          a.createdBy.className === user?.className &&
          a.createdBy.objectId === user?.objectId
      )
      .map((a) => a.id)
  );
export const selectAssetROIsFromUser = (user: Parse.Pointer) =>
  createSelector(
    selectRois,
    selectAssetAnalysisFromUser(user),
    (rois, analysisIds) =>
      rois.filter((roi) =>
        getLabelAnalysisIds([roi]).every((analysisId) =>
          analysisIds.includes(analysisId)
        )
      )
  );
export const selectROILabelCountFromActiveROIs = (labelId: string) =>
  createSelector(
    selectActiveROIS,
    labelsWithThresholds,
    (rois, labelsWithThresholds) => {
      const roiLabels: string[] = AnalysisUtils.flatten(
        (rois || []).map((roi) =>
          roi.labels.reduce((acc, currLabel) => {
            return [
              ...acc,
              ...Object.keys(currLabel.labels).filter(
                (l) => currLabel.labels[l] >= (labelsWithThresholds[l] ?? 0)
              ),
            ];
          }, [])
        )
      );

      return roiLabels.filter((label) => label === labelId).length;
    }
  );

export const selectROILabelCount = (labelId: string) =>
  createSelector(
    selectRois,
    labelsWithThresholds,
    (rois, labelsWithThresholds) => {
      const roiLabels: string[] = AnalysisUtils.flatten(
        (rois || []).map((roi) =>
          roi.labels.reduce((acc, currLabel) => {
            return [
              ...acc,
              ...Object.keys(currLabel.labels).filter(
                (l) => currLabel.labels[l] >= (labelsWithThresholds[l] ?? 0)
              ),
            ];
          }, [])
        )
      );

      return roiLabels.filter((label) => label === labelId).length;
    }
  );

export const selectSelectedROIS = createSelector(selectActiveROIS, (rois) =>
  rois?.filter((roi) => roi?.selected)
);

export const selectVisibleROIS = createSelector(
  selectActiveROIS,
  selectVisibleLabels,
  labelsWithThresholds,
  (rois, visibleLabels, labelsWithThresholds) =>
    rois.filter((roi) => {
      const roiLabels = roi.labels?.reduce(
        (acc, label) => ({
          ...acc,
          ...Object.keys(label.labels).reduce(
            (all, l) => ({ ...all, [l]: label.labels[l] }),
            {}
          ),
        }),
        {}
      );

      return Object.keys(roiLabels).some(
        (label) =>
          visibleLabels.includes(label) &&
          roiLabels[label] >= (labelsWithThresholds[label] ?? 0)
      );
    })
);

export const selectedLabelsWithAnalysisReq = createSelector(
  selectActiveAssetContextAnalysis,
  selectedLabels,
  selectActiveAssetFindings,
  (activeAnalysisInfo, labels, activeFindings) => {
    const pipelines: string[] = Array.from(
      new Set(labels.map((label) => label.pipelineId))
    );
    const analysesRequest = pipelines.map((p) => ({
      ...activeAnalysisInfo.analysisRequest,
      pipelineId: p,
    }));

    const activeAnalysis = activeAnalysisInfo.analysisArray;
    const needsNewAnalysis = !pipelines.every((id) =>
      activeAnalysis.find((a) => a.pipelineId === id)
    );

    if (needsNewAnalysis) {
      return {
        selectedLabels: labels,
        analysesRequest,
        activeAnalysis: [],
        analysisLabels: [],
        analysisCreation: true,
        findingsCreation: true,
      };
    }

    if (
      labels.some(
        (l) => !activeFindings.map((f) => f.taskId).includes(l.taskId)
      )
    ) {
      return {
        selectedLabels: labels,
        analysesRequest,
        activeAnalysis: activeAnalysisInfo.analysisArray,
        analysisLabels: [],
        analysisCreation: false,
        findingsCreation: true,
      };
    }
    return {
      analysisLabels: labelsToAnalysisLabelFormat(
        labels,
        activeAnalysisInfo.analysisArray,
        activeFindings
      ),
      activeAnalysis: activeAnalysisInfo.analysisArray,
      analysesRequest: [],
      selectedLabels: [],
      analysisCreation: false,
      findingsCreation: false,
    };
  }
);

export const selectActiveCount = createSelector(
  selectSampleCounterFeature,
  analysisState,
  (state: SampleCounterState, analysisState) => {
    const labelCountEntity = Object.values(state?.labelCount.entities).find(
      (labelCount) => labelCount.analysisStateId === analysisState?.id
    );
    return labelCountEntity ? labelCountEntity : undefined;
  }
);

export const labelCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveLabelCountReview,
  (mode, countEntity, reviewLabelCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? reviewLabelCount.labelCount
      : countEntity?.labelCount ?? [];
  }
);

export const multiLabelCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveLabelCountReview,
  selectActiveMultiLabelReviewCount,
  (mode, countEntity, reviewLabelCount, multiLabelReviewCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? multiLabelReviewCount
      : countEntity?.multiLabelCount;
  }
);

export const singleLabelCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveLabelCountReview,
  selectActiveSingleLabelReviewCount,
  (mode, countEntity, reviewLabelCount, singleLabelReviewCount) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? singleLabelReviewCount
      : countEntity?.singleLabelCount;
  }
);

export const labelsToSubstract = createSelector(
  multiLabelCount,
  singleLabelCount,
  disabledLabelsForCounter,
  (multiLabelDetail, singleLabelDetail, labelsToFilter) => {
    if (labelsToFilter.length < 1) return 0;
    if (labelsToFilter.length === 1) {
      const label = Object.keys(singleLabelDetail || {}).find((label) =>
        label.includes(labelsToFilter[0])
      );
      return label ? singleLabelDetail[label] : 0;
    } else {
      let roisToSubstract = 0;
      Object.keys(singleLabelDetail || {}).forEach((label) => {
        if (labelsToFilter.includes(label)) {
          roisToSubstract += singleLabelDetail[label];
        }
      });
      const allFilters = getAllPossibleCounterFilters(labelsToFilter);
      Object.keys(multiLabelDetail || {}).forEach((label) => {
        if (allFilters.includes(label)) {
          roisToSubstract += multiLabelDetail[label];
        }
      });
      return roisToSubstract;
    }
  }
);
export const totalCount = createSelector(
  selectMode,
  selectActiveCount,
  selectActiveReviewCount,
  selectActiveLabelCountReview,
  labelsToSubstract,
  (mode, countEntity, reviewCount, reviewLabelCount, substractLabels) => {
    return mode === Mode.REVIEW &&
      countEntity?.analysisStateId === reviewLabelCount?.analysisStateId
      ? (reviewCount ?? 0) - substractLabels
      : (countEntity?.totalCount ?? 0) - substractLabels;
  }
);

export const allFindingsFromCurrentUser = createSelector(
  selectFindings,
  selectCurrentAnalyst,
  (findings, currAnalyst) => {
    return findings.filter(
      (f) => f.createdBy.objectId === currAnalyst.objectId
    );
  }
);

export const roiLabelsFromUser = createSelector(
  selectRois,
  allFindingsFromCurrentUser,
  (rois, findingsFromCurrentUser) => {
    const findingIds = findingsFromCurrentUser.map((f) => f.id);

    return rois
      .flatMap((roi) => roi.labels)
      .filter((label) => findingIds.includes(label.findingId));
  }
);

export const totalPOICount = createSelector(
  roiLabelsFromUser,
  disabledLabelsForCounter,
  (roiLabels, labelsToFilter) => {
    return roiLabels.filter(
      (roi) => !Object.keys(roi.labels).every((l) => labelsToFilter.includes(l))
    ).length;
  }
);

export const selectPOILabelCount = (labelId: string) =>
  createSelector(
    labelCount,
    disabledLabelsForCounter,
    (labelCount, labelsToFilter) => {
      if (labelsToFilter.includes(labelId)) return -1;
      return labelCount.find((c) => c.labelId === labelId)?.rois ?? 0;
    }
  );

export const sampleReady = createSelector(
  refStripItemsReady,
  mosaicMode,
  selectActiveSample,
  selectMosaicLoading,

  (itemsReady, mosaicMode, selectedSample, mosaicLoading) => {
    const ready = mosaicMode
      ? itemsReady && mosaicLoading === false
      : itemsReady;
    return ready ? selectedSample : undefined;
  }
);

export const unlabeledRoiCount = createSelector(
  selectRois,
  totalCount,
  (rois, totalCount) => {
    const unlabeledCount = rois
      .flatMap((roi) => roi.labels)
      .filter((label) => Object.keys(label.labels).length === 0).length;

    return {
      count: unlabeledCount,
      percentage:
        totalCount > 0
          ? Number(((unlabeledCount / totalCount) * 100).toFixed(2))
          : 0,
    };
  }
);

export const hasUnlabeledRois = createSelector(
  unlabeledRoiCount,
  (unlabeledCount) => {
    return unlabeledCount?.count !== 0;
  }
);

export const unlabeledRois = createSelector(selectRois, (rois) => {
  return rois.filter((roi) =>
    roi.labels.some((label) => Object.keys(label.labels).length === 0)
  );
});

export const fetchingCropsProgress = createSelector(
  numAssets,
  selectAssetsCropsProcessed,
  selectAssetsFromSyncedFindings,
  (numAssets, assetsProcessed, unsyncedAssets) => {
    const processed =
      unsyncedAssets.length > 0
        ? Math.abs(assetsProcessed.length - unsyncedAssets.length)
        : assetsProcessed.length;

    return numAssets === 0
      ? 0
      : assetsProcessed.length
      ? Math.floor((processed / numAssets) * 100)
      : 0;
  }
);

export const findingUuidsWithoutCropsInfo = createSelector(
  selectRois,
  selectFindings,
  (rois, findings) => {
    const roisWithoutCrops = rois.filter((roi) => !roi.cropFileName);
    const findingsFiltered = [];
    roisWithoutCrops.forEach((roi) => {
      roi.labels.forEach((l) => {
        const finding = findings.find((f) => f.id === l.findingId);
        findingsFiltered.push(finding);
      });
    });
    return Array.from(new Set([...findingsFiltered]));
  }
);

export const taskTypesFromSelectedLabels = createSelector(
  selectedLabels,
  selectTaskGroupsWithROITasks,
  (selectedLabels, tasks) => {
    if (selectedLabels.length === 0) return [];

    const taskIds =
      Array.from(new Set([...selectedLabels.map((l) => l.taskId)])) ?? [];

    const taskTypesEnabled = taskIds
      .map((id) => {
        const task = tasks.find((t) => t.stepId === id);
        return task.type ?? null;
      })
      .filter((type) => type);

    return Array.from(new Set([...taskTypesEnabled]));
  }
);
