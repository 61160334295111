import { createAction, props } from "@ngrx/store";
import { Label } from "../protocol/protocol.reducer";
import { IAssetROI, StepTask } from "@telespot/sdk";
import {
  AnalysisLabel,
  IAnalysis,
  IFinding,
  Mode,
  POI,
  ROI,
} from "./analysis.reducer";
import { FindingDetails, UpdateAnalysisDetails } from "../history";

export interface NewXOI {
  x: number;
  y: number;
  w?: number;
  h?: number;
  time?: number;
  labels: AnalysisLabel[];
}

export interface LabelInfo {
  activeAssetId: string;
  pipelineId: string;
  taskId: string;
  labels: string[];
}

export interface AnalysisRequest {
  createdBy: Parse.Pointer;
  sampleId: string;
  assetId?: string;
  pipelineId: string;
}

export interface AnalysisChange<T> {
  previous: T;
  current: T;
  assetId?: string;
}

export interface FindingChange<T> {
  previous: T;
  current: T;
  uuid: string;
  analysisId?: string;
  assetId?: string;
  type?: StepTask;
  version: number;
  maskFileName?: string;
  emptyData: boolean;
}

export const analysisActionError = createAction(
  "[ANALYSIS] Action Error",
  props<{ error: string }>()
);

export const loadAssetAnalysis = createAction(
  "[ANALYSIS] Load Asset Analysis",
  props<{
    assetId: string;
    createdBy: Parse.Pointer;
    sampleId: string;
  }>()
);

export const assetAnalysisLoaded = createAction(
  "[ANALYSIS] Asset Analysis Loaded",
  props<{
    analysis: IAnalysis[];
    findings: IFinding[];
    rois: ROI[];
  }>()
);

export const updateAnalysis = createAction(
  "[ANALYSIS] Update Analysis findings",
  props<{ findings: any[] }>()
);

export const loadSampleAnalysis = createAction(
  "[ANALYSIS] Load Sample Analysis",
  props<{
    sampleId: string;
    createdBy: Parse.Pointer;
  }>()
);

export const createAnalysis = createAction(
  "[ANALYSIS] Create Analysis",
  props<{
    data?: any;
    createdBy: Parse.Pointer;
    sampleId: string;
    assetId?: string;
    pipelineId: string;
  }>()
);

export const copyAnalysis = createAction(
  "[ANALYSIS] Copy Analysis",
  props<{
    authUser: Parse.Pointer;
  }>()
);
export const analysisCopied = createAction(
  "[ANALYSIS] Analysis copied",
  props<{
    authUser: Parse.Pointer;
    activeAnalysisIds: string[];
  }>()
);

export const createAnalysisFromROIs = createAction(
  "[ANALYSIS] Create new Analysis when creating a ROI",
  props<{
    analysesRequest: AnalysisRequest[];
    selectedLabels: Label[];
    rois: IAssetROI[];
  }>()
);

export const createFindingsFromROIs = createAction(
  "[FINDING] Create new Findings when setting ROIs",
  props<{
    analysis: IAnalysis[];
    selectedLabels: Label[];
    rois: IAssetROI[];
  }>()
);

export const createSegmAnalysis = createAction(
  "[ANALYSIS] Create new segmentation Analysis"
);

export const segmAnalysisCreated = createAction(
  "[ANALYSIS] New segmentation Analysis created",
  props<{
    assetId: string;
    sampleId: string;
    createdBy: Parse.Pointer;
    pipelineId: string;
    taskId: string;
  }>()
);

export const sampleAnalysisLoaded = createAction(
  "[ANALYSIS] Sample Analysis Loaded",
  props<{ analysis: IAnalysis[]; findings: IFinding[] }>()
);
export const preSyncAnalysis = createAction("[ANALYSIS] Pre-Sync Changes");
export const syncAnalysis = createAction("[ANALYSIS] Sync Changes");
export const analysisSynced = createAction(
  "[ANALYSIS] Changes Synced",
  props<{ idChanges: AnalysisChange<string>[] }>()
);
export const findingsSynced = createAction(
  "[FINDINGS] Finding Changes Synced",
  props<{ idChanges: FindingChange<string>[] }>()
);
export const findingsUnsynced = createAction(
  "[FINDING] Mark finding as unsynced",
  props<{ finding: IFinding }>()
);

export const findingsSyncedSaved = createAction(
  "[FINDING] Synced Findings Saved in the State"
);

export const exitAnalysis = createAction("[ANALYSIS] Exit Analysis");

export const clearData = createAction(
  "[ANALYSIS] Reset analysis, findings and rois to initial state"
);

export const setROIs = createAction(
  "[ROIS] Set ROI",
  props<{ rois: NewXOI[] }>()
);
export const removeROIs = createAction(
  "[ROIS] Remove ROIs",
  props<{ rois?: ROI[]; selectedROIs?: (ROI | POI)[] }>()
);
export const setSelectedROIs = createAction(
  "[ROIS] Select rois",
  props<{ rois: (ROI | POI)[]; replace?: boolean }>()
);
export const selectROIsFromRegion = createAction(
  "[ROIS] Select rois from region",
  props<{ bounds?: IAssetROI; activeAnalysisIds?: string[] }>()
);
export const dragCrop = createAction(
  "[ROIS] Update roi labels when dragging in mosaic mode",
  props<{ roiId: string; previousLabels: string[]; newLabels: string[] }>()
);
export const changeCropLabels = createAction(
  "[ROIS] Update roi labels through menu",
  props<{ roiId: string; findingId: string; newLabel: string }>()
);

export const addLabel = createAction(
  "[ROIS] Add New Label to a Mosaic ROI",
  props<{ roiId: any; newLabel: string }>()
);
export const updateROI = createAction(
  "[ROIS] Update rois",
  props<{ roi: ROI | POI; changes?: Partial<ROI> }>()
);
export const updateROILabels = createAction(
  "[ROIS] Update Label",
  props<{ label: LabelInfo; replacePrevLabels: boolean; authUserId: string }>()
);
export const switchMode = createAction(
  "[ANALYSIS] Switch Mode",
  props<{ mode: Mode }>()
);

export const discardAnalysis = createAction(
  "[ANALYSIS] Discard Analysis",
  props<{ analysisDiscarded: AnalysisRequest[]; hasROI: boolean }>()
);

export const analysisDiscarded = createAction(
  "[ANALYSIS] Analysis discarded",
  props<{
    analysisDiscardedIds: string[];
    findingsDiscardedIds: string[];
    hasROI: boolean;
  }>()
);
export const discardAnalysisChange = createAction(
  "[ANALYSIS] Discard finding changes from Analysis",
  props<{ details: UpdateAnalysisDetails }>()
);
export const discardRemoveROIs = createAction(
  "[ROIS] Discard remove ROIs change",
  props<{ rois: ROI[] }>()
);
export const discardUpdateROI = createAction(
  "[ROIS] Discard update ROI change",
  props<{ rois: ROI[] }>()
);
export const discardSetROIs = createAction(
  "[ROIS] Discard set ROIs",
  props<{ xois: NewXOI[] }>()
);
export const discardFindingsFromROIs = createAction(
  "[ROIS] Discard create Findings from ROIs",
  props<{ details: FindingDetails[]; rois: IAssetROI[] }>()
);
export const discardAllChanges = createAction("[ANALYSIS] Discard all changes");
export const discardSync = createAction(
  "[ROIS] Sync analysis and findings after discarding changes"
);
export const setReviewCounters = createAction(
  "[ROIS] Set label counters for review mode",
  props<{
    newROIs: (ROI | POI)[];
    oldROIs: (ROI | POI)[];
    authUser: Parse.Pointer;
  }>()
);

export const loadingMask = createAction(
  "[ROIS] Set loadingMask value",
  props<{
    loading: boolean;
  }>()
);

export const updateLoading = createAction(
  "[ROIS] Set loading value",
  props<{
    loading: boolean;
  }>()
);

export const loadMosaic = createAction(
  "[MOSAIC] Load mosaic",
  props<{
    nextToken?: string;
    override?: boolean;
    assetsProcessed: string[];
  }>()
);
export const mosaicRoisLoaded = createAction(
  "[MOSAIC] Mosaic rois loaded in state",
  props<{
    analysis: IAnalysis[];
    findings: IFinding[];
    rois: ROI[];
    lastBatch: boolean;
  }>()
);

export const deleteUnlabeledRois = createAction(
  "[ROIS] Delete unlabeled rois",
  props<{
    unlabeledRois: (ROI | POI)[];
  }>()
);

export const deleteObjects = createAction(
  "[ROIS] Delete findings, analysis and rois from state",
  props<{
    findingUuids: string[];
  }>()
);
