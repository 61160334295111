import {
  CdkVirtualScrollViewport,
  FixedSizeVirtualScrollStrategy,
  VIRTUAL_SCROLL_STRATEGY,
} from "@angular/cdk/scrolling";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Injectable,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import {
  analysisState,
  assetFilters,
  assetsFiltered,
  bookmarkedAssetIndex$,
  ISampleItem,
  loadAssetIndex,
  requestStripData,
  SampleAnalysisService,
  selectAssetIndex,
} from "@telespot/analysis-refactor/data-access";

import { combineLatest, fromEvent, Observable, Subject } from "rxjs";
import { debounceTime, takeUntil, withLatestFrom } from "rxjs/operators";

@Injectable()
export class CustomVirtualScrollStrategy extends FixedSizeVirtualScrollStrategy {
  constructor() {
    super(90, 45, 45);
  }
}

@Component({
  selector: "ts-sample-reference-strip",
  templateUrl: "./sample-reference-strip.component.html",
  styleUrls: ["./sample-reference-strip.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: VIRTUAL_SCROLL_STRATEGY, useClass: CustomVirtualScrollStrategy },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SampleReferenceStripComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  elements$: Observable<ISampleItem[]> = this._store.select(assetsFiltered);
  private analysisState$ = this._store.select(analysisState);
  public readonly selectedIndex$: Observable<number> =
    this._store.select(selectAssetIndex);
  public readonly assetFilters$ = this._store.select(assetFilters);

  private _maxIndex: number;

  bookmarkedAssetIndex$ = this._store.select(bookmarkedAssetIndex$);
  private _destroy$ = new Subject<void>();

  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    private _sampleAnalysisService: SampleAnalysisService
  ) {
    this.elements$
      .pipe(takeUntil(this._destroy$))
      .subscribe((elements) => (this._maxIndex = elements.length - 1));
  }

  ngOnInit() {
    fromEvent<KeyboardEvent>(document, "keydown")
      .pipe(
        debounceTime(350),
        withLatestFrom(this.selectedIndex$),
        takeUntil(this._destroy$)
      )
      .subscribe(([i, currentIndex]) => {
        if (i.key === "39") {
          currentIndex + 1 > this._maxIndex
            ? this.selectItem(this._maxIndex)
            : this.selectItem(currentIndex + 1);
        } else if (i.key === "37") {
          currentIndex - 1 < 0
            ? this.selectItem(0)
            : this.selectItem(currentIndex - 1);
        }
      });
  }

  ngAfterViewInit() {
    combineLatest([
      this.analysisState$,
      this.viewPort.renderedRangeStream,
      this.assetFilters$,
    ])
      .pipe(takeUntil(this._destroy$))
      .subscribe(([_analysisState, limits]) => {
        const request = {
          createdBy:
            _analysisState?.user?.toPointer() ??
            _analysisState?.algorithm?.toPointer(),
          sampleId: _analysisState?.sample?.id,
          limits,
        };
        this._store.dispatch(
          requestStripData({ requestRefStripData: request })
        );
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  selectItem(index: number, id?: string) {
    this._store.dispatch(loadAssetIndex({ index }));
    if (id) this._sampleAnalysisService.updateURLParams(id);
  }
}
