import {
  Algorithms,
  Analysis,
  Asset,
  AssetStatus,
  Finding,
  Pipeline,
  Sample,
  StepTask,
  User,
} from "@telespot/sdk";
import uid from "uid";
import { IAnalysis, Label, LabelDetail, ROI } from "../../state";
import { v4 } from "uuid";
import { RoiItem } from "@telespot/web-core";

export class AnalysisMapper {
  public static fromStateAnalysis(analysis: IAnalysis): Analysis {
    const parseAnalysis = new Analysis();

    const isUser = analysis.createdBy.className === User.className;

    const analysedByKey = isUser ? "createdBy" : "algorithm";
    const analysedByPointer = (isUser ? User : Algorithms).createWithoutData(
      analysis.createdBy.objectId
    );

    if (analysis.assetId !== undefined)
      parseAnalysis.asset = Asset.createWithoutData(analysis.assetId);

    if (analysis.id.startsWith("copy:")) {
      parseAnalysis.assetStatus = AssetStatus.reviewed;
    }
    parseAnalysis.pipeline = Pipeline.createWithoutData(
      analysis.pipelineId
    ) as Pipeline;

    parseAnalysis.sample = Sample.createWithoutData(analysis.sampleId);
    parseAnalysis.uuid = v4();

    parseAnalysis.set(analysedByKey, analysedByPointer);

    return parseAnalysis;
  }

  public static toStateAnalysis(
    analysis: Analysis,
    finding?: Finding
  ): IAnalysis {
    const createdBy = analysis?.createdBy
      ? analysis.createdBy.toPointer()
      : finding
      ? Algorithms.createWithoutData(finding.creatorId)?.toPointer()
      : null;

    return {
      id: analysis?.id,
      synced: true,
      createdBy,
      sampleId: analysis?.sample?.id,
      assetId: analysis?.asset?.id,
      isSampleAnalysis: !analysis?.asset?.id,
      pipelineId: analysis?.pipeline?.id,
      fetchedPartially: false,
    };
  }

  public static getCustomLabels(analysis: Analysis): Label[] {
    if (!analysis) return [];

    const customLabels: Label[] = [];

    const tasks = analysis.analysisType.tasks.filter(
      (task) => task.roiSelection
    );

    for (const task of tasks) {
      const availableOptionsInTask = task.options?.map((o) => o.name) ?? [];
      const analysisResponses = analysis?.data[task.name] ?? {};

      const customOptions = Object.keys(analysisResponses).filter(
        (key) => !availableOptionsInTask.includes(key)
      );
      //fix
      // customOptions.forEach((optionName) =>
      //   customLabels.push({
      //     analysisTypeId: analysis.analysisType.id,
      //     category: task.name,
      //     value: optionName,
      //     selected: false,
      //     visible: true,
      //     pinned: false,
      //     removeFromCounter: false,
      //   })
      // );
    }

    return customLabels;
  }

  public static extractFindingROIs(
    finding: Finding,
    labelIds: string[],
    allowCustomLabels: boolean
  ): ROI[] {
    if (!finding) return [];
    const roisToAdd = [];

    const createdByAI = finding.creatorEntity === "algorithm";

    if (
      finding.type !== StepTask.POSITION &&
      finding.type !== StepTask.ROIDETECTION
    )
      return roisToAdd;

    const data = allowCustomLabels
      ? finding.data?.content
      : finding.data?.content
          .map((roi) => ({
            ...roi,
            labels: Object.fromEntries(
              Object.entries(roi.labels).filter(([labelUUID]) =>
                labelIds.includes(labelUUID)
              )
            ),
          }))
          .filter((roi) => Object.keys(roi.labels).length > 0);

    if (!data) return roisToAdd;

    roisToAdd.push(
      ...data.map((roiData) => {
        let labels = roiData.labels;
        if (createdByAI) {
          //Get label max probability for AI rois
          const values = Object.values((roiData.labels as LabelDetail) || {});

          if (!values.length) labels = {};
          const maxProb = Math.max(...values);

          const indices = values.reduce((acc, val, index) => {
            if (val === maxProb) {
              acc.push(index);
            }
            return acc;
          }, []);
          if (indices.length > 0) {
            labels = indices.reduce((acc, index) => {
              acc[Object.keys(roiData.labels)[index]] = maxProb;
              return acc;
            }, {});
          } else {
            labels = {};
          }
        }

        const assetWidth = finding.analysis?.asset?.data?.width ?? 1;
        const assetHeight = finding.analysis?.asset?.data?.height ?? 1;

        return {
          x: roiData.x0 * assetWidth,
          y: roiData.y0 * assetHeight,
          w: (roiData.x1 - roiData?.x0) * assetWidth,
          h: (roiData?.y1 - roiData?.y0) * assetHeight,
          ...(roiData?.t && {
            time: roiData?.t,
          }),
          id: uid(6),
          selected: false,
          isAIResult: false,
          labels: [
            {
              analysisId: finding.analysis?.id,
              findingId: finding.id,
              labels,
            },
          ],
        };
      })
    );

    return roisToAdd;
  }

  public static getAnalysisFromMosaicRois(items: RoiItem[]) {
    const uniqueAnalysis = [];

    items.map((item) => {
      const isIncluded = uniqueAnalysis.find(
        (analysis) => analysis.id === item.analysisId
      );

      if (!isIncluded) {
        uniqueAnalysis.push({
          id: item.analysisId,
          synced: true,
          assetId: item.assetId,
          sampleId: item.sampleId,
          isSampleAnalysis: false,
          pipelineId: item.pipelineId,
          createdBy:
            item.creatorEntity === "user"
              ? User.createWithoutData(item?.creatorId).toPointer()
              : Algorithms.createWithoutData(item?.creatorId).toPointer(),
          fetchedPartially: true,
        });
      }
    });

    return uniqueAnalysis;
  }
}
