import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";
import {
  AnalysisLabel,
  DragDropService,
  Label,
  POI,
  ProtocolSpecification,
  ROI,
  RoiService,
  categoryLabels,
  changeCropLabels,
  dragCrop,
  groupPositionTasks,
  labelValues,
  multipleFiltersActive,
  selectRois,
  updateMultipleFiltersFlag,
} from "@telespot/analysis-refactor/data-access";

import { MosaicService } from "@telespot/web-core";
import { Observable, Subject, combineLatest } from "rxjs";
import { map, startWith, take, takeUntil } from "rxjs/operators";

import { CropInfo } from "../sample-analysis-mosaics/sample-analysis-mosaics.component";
import { TranslateService } from "@ngx-translate/core";
import generateId from "uid";
import { validate as uuidValidate } from "uuid";
import { MatSelect } from "@angular/material/select";
import { FormControl } from "@angular/forms";

@Component({
  selector: "ts-sample-analysis-mosaics-selector",
  templateUrl: "./sample-analysis-mosaics-selector.component.html",
  styleUrls: ["./sample-analysis-mosaics-selector.component.scss"],
})
export class SampleAnalysisMosaicsSelectorComponent
  implements OnInit, OnDestroy
{
  public unlabeledCategory = {
    en: "Unlabeled",
    es: "Sin etiquetar",
    fr: "Non etiqueté",
    pt: "Não rotulado",
  };

  public unlabeledOption = {
    en: "All unlabeled",
    es: "No etiquetadas",
    fr: "Non etiqueté",
    pt: "Não rotulado",
  };

  public UNLABELED_COLOR = "#F08F43";
  labelControl = new FormControl();

  // Contextual Menu variables
  public contextMenuVisible = false;
  public contextMenuPosition = { x: 0, y: 0 };
  public selectedItemLabels: string[] | null = [];
  @ViewChild("contextMenu", { read: ElementRef })
  private contextMenuRef: ElementRef;

  // Protocol and Mosaics variables
  @Input() protocol: ProtocolSpecification[] = [];
  public readonly rois$ = this._store.select(selectRois);
  public assetProtocol$ = this._store.select(groupPositionTasks);

  public multipleFiltersActive$ = this._store.select(multipleFiltersActive);
  public roisObs$: Observable<(ROI | POI)[]> = this.rois$.pipe(
    map((rois) => rois)
  );
  public assetProtocolTasks;
  public currentLang;
  public containerId = "";

  // Selector variables
  public selectedLabel: string;
  public selectedLabels: string[] = [];
  public protocolLabels;

  public labels$ = this._store.select(labelValues).pipe(
    map((labels) => {
      const allLabels = [
        ...labels,
        {
          category: this.unlabeledCategory[this.currentLang ?? "en"],
          value: this.unlabeledOption[this.currentLang ?? "en"],
        },
      ];
      this.protocolLabels = allLabels;
      return allLabels;
    })
  );

  public filteredLabels$ = combineLatest([
    this.labels$,
    this.labelControl.valueChanges.pipe(startWith("")),
  ]).pipe(map(([labels, searchTerm]) => this.filterLabels(labels, searchTerm)));

  public categories$ = combineLatest([
    this._store.select(categoryLabels),
    this.filteredLabels$,
  ]).pipe(
    map(([categories, filteredLabels]) => {
      const allCategories = [
        ...categories,
        this.unlabeledCategory[this.currentLang ?? "en"],
      ];

      return allCategories.filter((category) =>
        filteredLabels.find((l) => l.category === category)
      );
    })
  );

  // Drag and Drop variables
  private _selectedCropROI: ROI | POI;
  private _rois: (ROI | POI)[] = [];
  public connectedDropLists$ = this.dragDropService.dropListIdsObservable$;
  public connectedDropLists = [];

  // Component LifeCycle variables
  private destroy$ = new Subject<void>();
  private registeredDropListIds: string[] = [];

  constructor(
    public dragDropService: DragDropService,
    private _mosaicService: MosaicService,
    private _roiService: RoiService,
    private _cdr: ChangeDetectorRef,
    private _store: Store,
    private translateService: TranslateService
  ) {
    this.currentLang =
      localStorage.getItem("user_language") ||
      this.translateService.currentLang;

    combineLatest([this.multipleFiltersActive$, this.connectedDropLists$])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([multipleFiltersActive, lists]) => {
        if (multipleFiltersActive) {
          const listFromSelectors = lists.filter((l) => !uuidValidate(l));
          return (this.connectedDropLists = listFromSelectors);
        }
        this.connectedDropLists = lists;
        this._cdr.detectChanges();
      });
  }

  /* COMPONENT LYFECYCLE MANAGEMENT FUNCTIONS */

  ngOnInit() {
    this.assetProtocol$.pipe(takeUntil(this.destroy$)).subscribe(
      (groups) =>
        (this.assetProtocolTasks = groups.reduce((acc, protocol) => {
          return acc.concat(protocol.tasks);
        }, []))
    );

    this.containerId = this.getContainerId();
    this.updateRegisteredDropList(this.containerId);
    this.connectedDropLists = this.dragDropService.getConnectedDropLists();

    this._cdr.detectChanges();
  }

  ngOnDestroy() {
    // Next two lines signal any subscriber to 'destroy$' observable to complete
    // their work before the component is gone for good. Helps avoid memory leaks.
    this.destroy$.next();
    this.destroy$.complete();

    // We also iterate through our registered drop list IDs to deregister them,
    // cleaning up any handlers tied to them. This ensures our dragService doesn't litter around.
    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
  }

  /* SELECTOR FUNCTIONS */

  public getLabels(labels: Label[], category: string): string[] {
    return labels
      .filter((item) => item.category === category)
      .map((l) => l.value);
  }

  public onLabelChange(label: string) {
    // Update 'selectedLabels' to the new value
    this.selectedLabel = label;
    this.roisObs$ = this.rois$.pipe(map((rois) => rois));
    this.containerId = this.getContainerId();

    if (!this.selectedLabel) return;
    if (this.selectedLabels.includes(this.selectedLabel)) return;
    this.selectedLabels.push(this.selectedLabel);
    this._store.dispatch(updateMultipleFiltersFlag({ active: true }));

    this.clearRegisteredDropList();
    this.containerId = this.getContainerId();
    this.labelControl.setValue(undefined);

    this.updateRegisteredDropList(this.containerId);
    this._cdr.detectChanges();
    this.selectedLabel = null;
  }

  public addLabelFilter() {
    // if (!this.selectedLabel) return;
    // if (this.selectedLabels.includes(this.selectedLabel)) return;
    // this.selectedLabels.push(this.selectedLabel);
    // this._store.dispatch(updateMultipleFiltersFlag({ active: true }));
    // this.clearRegisteredDropList();
    // this.containerId = this.getContainerId();
    // this.labelControl.setValue(undefined);
    // this.updateRegisteredDropList(this.containerId);
    // this._cdr.detectChanges();
    // this.selectedLabel = null;
  }

  public removeLabelFilter(index: number) {
    this.selectedLabels.splice(index, 1);
    this.containerId = this.getContainerId();
    this.updateRegisteredDropList(this.containerId);
    if (this.selectedLabels.length <= 1)
      this._store.dispatch(updateMultipleFiltersFlag({ active: false }));
    this._cdr.detectChanges();
  }

  /* CONTEXTUAL MENU FUNCTIONS */

  // HostListener decorates the method below to listen to global 'click' events.
  // The "$event" argument is automatically passed containing the event details.
  @HostListener("document:click", ["$event"])
  documentClick(event: MouseEvent): void {
    // Check if the click was outside our context menu's element
    if (!this.contextMenuRef?.nativeElement.contains(event.target)) {
      this.closeContextMenu(); // If it was outside, close the context menu.
    }
  }

  public openContextMenu(event: MouseEvent, roi: ROI | POI): void {
    event.preventDefault(); // Prevent the browser's default right-click menu from opening.
    event.stopPropagation(); // Stop this event from bubbling up to other event handlers.

    // Set the preliminary position of the context menu based on the mouse event's coordinates.
    this.contextMenuPosition.x = event.clientX; // X-coordinate where the click occurred.
    this.contextMenuPosition.y = event.clientY; // Y-coordinate where the click occurred.

    this._selectedCropROI = roi;

    const labels = this.getRoiLabels(roi.labels);

    labels.forEach((uuid) =>
      this.selectedItemLabels.push(this.getLabelValue(uuid))
    );

    this.contextMenuVisible = true; // Toggle visibility of context menu to true.

    // Trigger change detection to ensure that the context menu is rendered in the DOM.
    this._cdr.detectChanges();

    // After Angular has done with change detection, we can safely measure the actual
    // dimensions of the context menu for further adjustment, if needed.
    const rect = this.contextMenuRef.nativeElement.getBoundingClientRect();
    const bottomSpace = window.innerHeight - event.clientY; // Space available below the click point.

    // If there isn't enough space at the bottom of the window for the menu,
    // adjust its position upward by the height of the menu.
    if (rect.height > bottomSpace) {
      this.contextMenuPosition.y -= rect.height;
    }

    // Re-trigger change detection after adjusting position to ensure the adjustment
    // is rendered on the screen properly.
    this._cdr.detectChanges();
  }

  public closeContextMenu(): void {
    this.contextMenuVisible = false; // Hide the context menu
    this.selectedItemLabels = []; // Clear the selected item label.
    this._selectedCropROI = null;
  }

  public getLabelsFromRoi(cropInfo: CropInfo) {
    return Object.keys(
      this._rois
        .find((roi) => {
          return roi.id === cropInfo.roiID;
        })
        .labels.find((label) => label.findingId === cropInfo.findingId).labels
    );
  }

  public showCrop(labels: AnalysisLabel[]) {
    const roiLabels = (this.getRoiLabels(labels) || []).map((l) =>
      this.getLabelValue(l)
    );

    //Show unlabeled crops when selected Label is allUnlabeled
    if (roiLabels?.length === 0 && this.isAllUnlabeledSelected()) {
      return true;
    }

    return this.selectedLabels.every((l) => roiLabels.includes(l));
  }

  public onClick(label: Label): void {
    const labelName = this.getLabelValue(label.uuid);

    if (!this.selectedItemLabels.includes(labelName))
      this.selectedItemLabels.push(labelName);
    else {
      this.selectedItemLabels = this.selectedItemLabels.filter(
        (label) => label !== labelName
      );
    }

    this._store.dispatch(
      changeCropLabels({
        roiId: this._selectedCropROI.id,
        findingId: this._selectedCropROI?.labels[0].findingId,
        newLabel: label.uuid,
      })
    );
  }

  /* DRAG AND DROP FUNCTIONS */

  public onDrop(event: CdkDragDrop<CropInfo[]>) {
    // Check if the drag and drop event occurred within the same container.
    if (event.previousContainer === event.container) {
      // Since the item was moved within the same container, simply rearrange the item's position in the array.
      //review
      // moveItemInArray(
      //   event.container.data, // Data array containing 'CropInfo' items.
      //   event.previousIndex, // Previous index of the dragged item.
      //   event.currentIndex // Current/new index where the item is dropped.
      // );
    } else {
      // Extract 'CropInfo' object from the dragged item's payload.
      const draggedCropInfo = event.item.data;
      const roiID: string = draggedCropInfo.roiID;
      const origin = this.getLabelsFromContainerId(
        event.previousContainer.id
      ).map((l) => this.getLabelUuid(l));
      const dest = this.getLabelsFromContainerId(event.container.id).map((l) =>
        this.getLabelUuid(l)
      );

      // Dispatch an action to update the store, indicating that the crop has been dragged from one label to another.
      this._store.dispatch(
        dragCrop({ roiId: roiID, previousLabels: origin, newLabels: dest })
      );
    }
  }

  onDragStart(): void {
    if (this.contextMenuVisible) this.closeContextMenu();
  }
  onDragEnter(): void {
    if (this.contextMenuVisible) this.closeContextMenu();
  }

  /* HELPER FUNCTIONS */

  public objectKeys(obj: any): string[] {
    return Object.keys(obj); // Convert object keys into a string array and return them.
  }

  public getCrop(roiId: string) {
    const url = localStorage.getItem(`crop_${roiId}`);
    if (!url) {
      return null;
    }
    const sanitizedUrl = this._mosaicService.getCropSanitizedUrl(url);
    return sanitizedUrl;
  }

  getRoiLabels(labels: AnalysisLabel[]) {
    return labels.reduce(
      (acc, currLabel) => [...acc, ...Object.keys(currLabel.labels)],
      []
    );
  }

  getColor() {
    if (this.isAllUnlabeledSelected()) return this.UNLABELED_COLOR;

    return this._roiService.getModelColor(
      this.selectedLabels.map((l) => this.getLabelUuid(l)),
      true
    );
  }

  getLabelColor(label) {
    const unlabeled = this.unlabeledOption[this.currentLang ?? "en"];
    if (label === unlabeled) return this.UNLABELED_COLOR;
    return this._roiService.getModelColor([this.getLabelUuid(label)], true);
  }

  getLabelValue(uuid: string) {
    return (this.protocolLabels || []).find((l) => l.uuid === uuid)?.value;
  }

  getLabelUuid(value: string) {
    return (this.protocolLabels || []).find((l) => l.value === value)?.uuid;
  }

  getContainerId() {
    const id = this.selectedLabels.join("/") + "+" + generateId(4);
    return id;
  }

  getSafeUrl(filename) {
    console.log("isundefined", filename);
    return this._mosaicService.getCropURL(filename);
  }

  isAllUnlabeledSelected() {
    return this.selectedLabels.includes(
      this.unlabeledOption[this.currentLang ?? "en"]
    );
  }

  getAlternativeText() {
    return this.selectedLabels.join(",");
  }

  getLabelsFromContainerId(id: string) {
    const labelsId = id.substring(0, id.length - 5);
    return labelsId.split("/");
  }

  clearRegisteredDropList() {
    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
    this.registeredDropListIds = [];
  }

  updateRegisteredDropList(id: string) {
    this.clearRegisteredDropList();
    this.dragDropService.addDropListId(id);
    this.registeredDropListIds.push(id);
  }

  disabledLabelOption(label) {
    const unlabeledOption = this.unlabeledOption[this.currentLang ?? "en"];
    const isUnlabeledOption = unlabeledOption === label;
    if (isUnlabeledOption && this.selectedLabels.length > 0) return true;
    if (this.selectedLabels.includes(unlabeledOption)) return true;
    return this.selectedLabels.includes(label);
  }

  filterLabels(
    labels:
      | Label[]
      | {
          category: any;
          value: any;
        }[],
    searchTerm: string
  ) {
    if (!searchTerm) return labels;
    const filterValue = searchTerm.toLowerCase();

    return labels.filter((l) => l?.value.toLowerCase().includes(filterValue));
  }
}
