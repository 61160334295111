import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SyncState, SyncedItemType, getSyncedItems } from "./sync.reducer";

export const syncFeatureKey = "sync";

export const selectSyncFeature =
  createFeatureSelector<SyncState>(syncFeatureKey);

export const selectSyncedItems = createSelector(
  selectSyncFeature,
  getSyncedItems
);

export const selectSyncedItemsByType = (type: SyncedItemType) =>
  createSelector(selectSyncedItems, (items) =>
    items.filter((i) => i.type === type)
  );

export const selectAssetsFromSyncedFindings = createSelector(
  selectSyncedItemsByType(SyncedItemType.FINDING),
  (findings) => {
    const uniqueAssets = new Set<string>();

    for (const f of findings) {
      if (uniqueAssets.has(f?.assetId)) continue;
      uniqueAssets.add(f?.assetId);
    }

    return Array.from(uniqueAssets);
  }
);
