import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import * as ProtocolActions from "./protocol.actions";
import { SampleAnalysisService } from "../../services/sample-analysis/sample-analysis.service";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { EMPTY, from, of } from "rxjs";

import { Store } from "@ngrx/store";
import {
  assetAnalysisLoaded,
  mosaicRoisLoaded,
  setSelectedROIs,
  updateROILabels,
} from "../analysis/analysis.actions";
import {
  selectHasSegmentationTasks,
  selectLabelsArray,
  selectMultipleSelection,
} from "./protocol.selectors";
import { selectActiveSample, selectAsset } from "../../+state";
import { AnalysisLabel, getCustomLabels } from "../analysis/analysis.reducer";
import { ProtocolAnalysisService } from "../../services/protocol-service/protocol.service";
import {
  ProtocolMapper,
  ProtocolService,
} from "@telespot/protocols/data-access";

@Injectable()
export class ProtocolEffects {
  loadProtocols$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.loadProtocol),
      mergeMap(({ sample }) => {
        const counterFilters =
          this.protocolAnalysisService.fetchLabelCounterFilters(
            sample?.case?.caseType?.id
          );

        return this.protocolAnalysisService.fetchSampleProtocol(sample.id).pipe(
          map((protocols) =>
            ProtocolActions.protocolLoaded({ protocols, counterFilters })
          ),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[loadProtocol]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  selectLabelFromROI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSelectedROIs),
      mergeMap(({ rois }) => {
        const firstROILabels =
          rois?.length > 0
            ? rois[0].labels.reduce(
                (acc, label) => [
                  ...acc,
                  ...Object.keys((label as AnalysisLabel).labels),
                ],
                []
              )
            : undefined;
        if (!firstROILabels) return EMPTY;
        return of({ labelIds: firstROILabels }).pipe(
          map(({ labelIds }) =>
            ProtocolActions.selectMultipleLabelIds({ labelIds })
          ),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[setSelectedROIs]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
  updateROILabels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.selectLabel),
      withLatestFrom(
        this.store$.select(selectMultipleSelection),
        this.store$.select(selectLabelsArray),
        this.store$.select(selectAsset),
        this.store$.select(selectHasSegmentationTasks)
      ),
      mergeMap(
        ([
          action,
          multiSelect,
          labelList,
          activeAsset,
          hasSegmentationTasks,
        ]) => {
          if (hasSegmentationTasks) return EMPTY;
          const label = {
            activeAssetId: activeAsset.id,
            pipelineId: labelList.find((label) => label?.uuid === action.uuid)
              .pipelineId,
            taskId: labelList.find((label) => label?.uuid === action.uuid)
              .taskId,
            labels: [action.uuid],
          };
          const authUserId = this.sampleAnalysisService.authUserId();
          return of({
            label,
            replacePrevLabels: !multiSelect,
            authUserId,
          }).pipe(
            map(({ label, replacePrevLabels, authUserId }) =>
              updateROILabels({ label, replacePrevLabels, authUserId })
            ),
            catchError((error) =>
              of(
                ProtocolActions.protocolActionError({
                  error: `[selectLabel]: ${error.message}`,
                })
              )
            )
          );
        }
      )
    )
  );

  assetAnalysisLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assetAnalysisLoaded, mosaicRoisLoaded),
      withLatestFrom(this.store$.select(selectLabelsArray)),
      mergeMap(([{ rois, findings, analysis }, allLabels]) => {
        const customLabels = getCustomLabels(rois, allLabels);
        const customLabelsInfo = customLabels.map((l) => {
          const finding = findings.find((f) => f.id === l.findingId);
          const an = analysis.find((a) => a.id === finding?.analysisId);

          return {
            labelId: l.labelId,
            taskId: finding.taskId,
            pipelineId: an.pipelineId,
          };
        });

        return from(
          this.protocolService.fetchLabelsInBatches(
            customLabels.map((l) => l.labelId)
          )
        ).pipe(
          map((labels) => ({
            filteredLabels: ProtocolMapper.filterLabels(
              labels,
              this.protocolService.currentLang
            ),
            customLabelsInfo,
          })),
          map(({ filteredLabels, customLabelsInfo }) => {
            return ProtocolActions.loadCustomLabels({
              customLabels: customLabelsInfo?.map((i) => ({
                uuid: i.labelId,
                taskId: i.taskId,
                pipelineId: i.pipelineId,
                value: filteredLabels.find((l) => l.uuid === i.labelId)?.value,
              })),
            });
          }),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[loadCustomLabels]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  saveCounterFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.updateOptionFromCounter),
      withLatestFrom(this.store$.select(selectActiveSample)),
      mergeMap(([{ labelId }, sample]) => {
        const counterFilters =
          this.protocolAnalysisService.setCounterFiltersInStorage(
            labelId,
            sample?.case?.caseType?.id
          );
        this.sampleAnalysisService.setCounterFilterQueryParam(counterFilters);
        return of({ labelId }).pipe(
          map((payload) => ProtocolActions.optionForCounterSaved(payload)),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[saveCounterFilters]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  saveNewCustomLabel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.addCustomLabel),
      mergeMap(({ customLabel }) => {
        if (!customLabel.value?.new)
          return of(ProtocolActions.customLabelAdded({ customLabel }));
        return this.protocolAnalysisService.saveCustomLabel(customLabel).pipe(
          map((savedLabels) =>
            ProtocolActions.customLabelAdded({ customLabel })
          ),
          catchError((error) =>
            of(
              ProtocolActions.protocolActionError({
                error: `[saveNewCustomLabel]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  setCounterFiltersFromQueryParams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolActions.setInitialCounterFilters),
      mergeMap(({ counterFilters, sampleId }) => {
        this.protocolAnalysisService.setInitialCounterFilters(
          counterFilters,
          sampleId
        );
        return of(
          ProtocolActions.counterFiltersFromParamsSaved({ counterFilters })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private sampleAnalysisService: SampleAnalysisService,
    private protocolAnalysisService: ProtocolAnalysisService,
    private protocolService: ProtocolService,
    private store$: Store
  ) {}
}
