import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
  production: true,
  api: {
    url: 'https://api.telespot.org/v1',
    appId: '30f7e5be-55b7-4efd-9cdf-f2c8b5cc4808',
    jsKey: '1241bb9a-aa39-4f76-a8d7-17012a668353',
    liveQueryServerURL: 'ws://alb-compute-prod.telespot.org/v1/parse',
    wsURL: 'wss://ws.telespot.org/v1',
  },
  logging: {
    level: 6,
  },
  serverLogging: {
    url: 'https://v14zjo9t1e.execute-api.eu-west-1.amazonaws.com/live/logs',
    apiKey: 'xu3Gcg5PPR79APuq2lvRc2vrQzrkV9pi8DaB8JWY',
    appId: '4e0cf95b-c961-425b-95d1-edf8be50d3d2',
    level: 5,
  },
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  VERSION: require('../../../../../../../package.json').version,
  external_links: {
    support: {
      en: 'https://docs.google.com/forms/d/e/1FAIpQLSe8eHPZHdW_VhNE2k0aOYfuGNbbijFKYAQdmF6H_zJOIYzntw/viewform?usp=pp_url&entry.54953920=__username__&entry.263402721=Digital+product+(app+or+web+platform)&entry.329953787=TeleSpot&entry.303001118=__OS__&entry.9952778=__browser__',
      es: 'https://docs.google.com/forms/d/e/1FAIpQLSd2JXeMHTQs9kuearhLC_9GzSC6ockfViJFHqm8Bxsi50_UHw/viewform?usp=pp_url&entry.2078446507=__username__&entry.1176919279=Producto+digital+(aplicaci%C3%B3n+o+plataforma+web)&entry.515917864=Plataforma+TeleSpot&entry.128607970=__OS__&entry.962894644=__browser__',
      pt: 'https://docs.google.com/forms/d/e/1FAIpQLSdnIpt9r2KKdPGxud5v97PJ-aeXScIyL-Hg64c_qMc_G8D6ZA/viewform?usp=pp_url&entry.1795727691=__username__&entry.1576041462=Produto+digital+(aplica%C3%A7%C3%A3o+ou+plataforma)&entry.1885793429=Plataforma+TeleSpot&entry.1057982736=__OS__&entry.1691540948=__browser__',
    },
    ai: {
      analyze: 'https://api.telespot.org/v1/ai/invocations',
    },
  },
  enable_logs: true,
  recorder: {
    enable: true,
    apiKey: "155d6a77246b7476d9dc3949e822dd69774fdc16",
    loggingLevel: 2,
    disable_spotlab_users: false,
  },
  firebase: {
    apiKey: 'AIzaSyDaUj137qIJ77VRWrE3I8a9bQU1JO6Cg6s',
    authDomain: 'telespot-web-b455f.firebaseapp.com',
    projectId: 'telespot-web-b455f',
    storageBucket: 'telespot-web-b455f.appspot.com',
    messagingSenderId: '433153992437',
    appId: '1:433153992437:web:fd8abd7544634f651307bc',
    measurementId: 'G-DMFJTQ148C',
  },
  serviceWorker: true,
} as const;
