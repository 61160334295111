import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  DragDropService,
  ProtocolSpecification,
  dragCrop,
  groupPositionTasks,
  labelValues,
  multipleFiltersActive,
  preSyncAnalysis,
  selectPOILabelCount,
  totalPOICount,
  unlabeledRoiCount,
  updateOptionFromCounter,
} from "@telespot/analysis-refactor/data-access";
import { TaskOption } from "@telespot/sdk";
import { Subject, combineLatest } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import { CropInfo } from "../sample-analysis-mosaics/sample-analysis-mosaics.component";

interface LabelCount {
  [uuid: string]: { count: number; percentage: number };
}
@Component({
  selector: "ts-sample-analysis-panel-mosaics",
  templateUrl: "./sample-analysis-panel-mosaics.component.html",
  styleUrls: ["./sample-analysis-panel-mosaics.component.scss"],
})
export class SampleAnalysisPanelMosaicsComponent implements OnInit, OnDestroy {
  // Protocol and Mosaics variables
  public labelCounts: LabelCount = {};
  public unlabeledCount = { count: 0, percentage: 0 };

  @Input() protocol: ProtocolSpecification[] = [];
  public assetProtocol$ = this._store.select(groupPositionTasks);
  public totalCount$ = this._store.select(totalPOICount);
  public unlabeledCount$ = this._store.select(unlabeledRoiCount);
  public multipleFiltersActive$ = this._store.select(multipleFiltersActive);
  public connectedDropLists$ = this.dragDropService.dropListIdsObservable$;
  public disableDragAndDrop = false;

  // Component LifeCycle variables
  private destroy$ = new Subject<void>();
  private registeredDropListIds: string[] = [];

  // Drag and Drop variables
  private labelDict: { [label: string]: string } = {};
  public protocolLabels;

  constructor(public dragDropService: DragDropService, private _store: Store) {
    this._store
      .select(labelValues)
      .pipe(takeUntil(this.destroy$))
      .subscribe((labels) => {
        const allLabels = [...labels];
        this.protocolLabels = allLabels;
        return allLabels;
      });
  }

  /* COMPONENT LYFECYCLE MANAGEMENT FUNCTIONS */

  ngOnInit() {
    // Whenever assetProtocol$ emits a value (which is after ngOnChanges has triggered), perform the following actions until this component is destroyed.
    this.assetProtocol$
      .pipe(takeUntil(this.destroy$)) // Stops the subscription when destroy$ emits to prevent memory leaks.
      .subscribe((assetProtocol) => {
        // Iterate through each group and their tasks in the filtered protocol.
        assetProtocol.forEach((group) => {
          group.tasks.forEach((task) => {
            task.options?.forEach((option) => {
              // Clear any previously registered drop list IDs to avoid duplicates or stale references.
              this.registeredDropListIds.forEach((id) =>
                this.dragDropService.removeDropListId(id)
              );
              this.registeredDropListIds = [];

              // Register new drop list IDs for the drag and drop functionality.
              // This is crucial for the dragDropService to keep track of valid drop zones.
              this.dragDropService.addDropListId(option.uuid);
              // Construct the label dictionary for Drag and Drop actions
              this.labelDict[option.name] = option.uuid;
            });
          });
        });
      });

    this.unlabeledCount$
      .pipe(takeUntil(this.destroy$))
      .subscribe((count) => (this.unlabeledCount = count));

    this.multipleFiltersActive$
      .pipe(takeUntil(this.destroy$))
      .subscribe((active) => {
        this.disableDragAndDrop = active;
      });
  }

  ngOnDestroy(): void {
    // It's cleanup time! Signal to the destroy$ observable's subscribers to complete their work as this component is about to be unmounted and destroyed.
    this.destroy$.next();
    this.destroy$.complete();

    // Iterate through registered drop list IDs and remove them from the dragDropService.
    // This prevents memory leaks by ensuring that we don't leave behind any orphaned event listeners or data.
    this.registeredDropListIds.forEach((id) =>
      this.dragDropService.removeDropListId(id)
    );
  }

  /* PROTOCOL FUNCTIONS */

  public getTotalCounts(taskOptions: TaskOption[]): {
    countTotal: number;
    percentageTotal: number;
  } {
    // The `reduce` function aggregates values across all `taskOptions` elements.
    return taskOptions.reduce(
      (acc, option) => {
        const count = this.labelCounts[option.uuid]?.count || 0; // Retrieves the count for the option, defaults to 0 if undefined.
        const percentage = this.labelCounts[option.uuid]?.percentage || 0; // Retrieves the percentage for the option, defaults to 0 if undefined.
        return {
          countTotal: acc.countTotal + count, // Increments the running count total.
          percentageTotal: acc.percentageTotal + percentage, // Increments the running percentage total.
        };
      },
      { countTotal: 0, percentageTotal: 0 } // Initializes the totals to 0 before accumulation starts.
    );
  }

  updateLabelsForCounter(labelId: string) {
    this._store.dispatch(
      updateOptionFromCounter({
        labelId,
      })
    );
  }

  /* DRAG AND DROP FUNCTIONS */

  public drop(event: CdkDragDrop<CropInfo[]>) {
    // Check if the dragged item is dropped in the same container it started in.
    if (event.previousContainer === event.container) {
      // Since the item was moved within the same container, simply rearrange the item's position in the array.
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      // Extract 'CropInfo' object from the dragged item's payload.
      const draggedCropInfo: CropInfo = event.item.data;
      const roiID: string = draggedCropInfo.roiID;
      const origin = this.getLabelsFromContainerId(
        event.previousContainer.id
      ).map((l) => this.getLabelUuid(l));

      const dest = event.container.id;

      // Dispatch an action to update the store, indicating that the crop has been dragged from one label to another.
      this._store.dispatch(
        dragCrop({ roiId: roiID, previousLabels: origin, newLabels: [dest] })
      );
    }
  }

  saveAnalysis() {
    this._store.dispatch(preSyncAnalysis());
  }

  public getLabelCount(labelId: string) {
    // Combina el Observable para el conteo de la etiqueta con el Observable del conteo total
    return combineLatest([
      this._store.select(selectPOILabelCount(labelId)),
      this.totalCount$,
    ]).pipe(
      map(([labelCount, totalCount]) => {
        if (labelCount === -1) {
          this.labelCounts[labelId] = {
            count: 0,
            percentage: 0,
          };
          return null;
        }
        // Calculamos el porcentaje usando los valores recibidos
        const percentage = totalCount > 0 ? (labelCount / totalCount) * 100 : 0;
        // Creamos el objeto con la estructura deseada
        this.labelCounts[labelId] = {
          count: labelCount,
          percentage: Math.round(percentage * 100) / 100, // Redondear al segundo decimal para una buena medida
        };
        return this.labelCounts[labelId];
      })
    );
  }

  getLabelsFromContainerId(id: string) {
    const labels = id.includes("+")
      ? id.substring(0, id.length - 5).split("/")
      : [id];
    return labels;
  }

  getLabelUuid(value: string) {
    return (this.protocolLabels || []).find((l) => l.value === value)?.uuid;
  }
}
