import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";

import { Store } from "@ngrx/store";
import { EMPTY, from, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
  mergeMap,
} from "rxjs/operators";
import { analysisState, markAssetAsAnalyzed } from "../../+state";

import {
  HistoryService,
  OperationType,
} from "../../services/history/history.service";
import {
  analysisSynced,
  createAnalysis,
  createAnalysisFromROIs,
  createFindingsFromROIs,
  discardAllChanges,
  discardSync,
  Mode,
  removeROIs,
  ROI,
  selectAnalysis,
  selectFindings,
  setROIs,
  switchMode,
  updateAnalysis,
  updateROI,
} from "../analysis";
import { selectSelectedROIS } from "../interfeature.selectors";
import { selectHasSegmentationTasks, selectLabel } from "../protocol";
import {
  clearHistoryState,
  historyActionError,
  registerAction,
} from "./history.actions";
import { CreateAnalysisDetails } from "./history.reducer";
import { selectActionsInHistory } from "./history.selectors";

@Injectable()
export class HistoryEffects {
  constructor(
    private actions$: Actions,
    private store$: Store,
    private historyService: HistoryService
  ) {}
  createAnalysisCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createAnalysis, createAnalysisFromROIs),
      switchMap((action) => {
        const { type, ...props } = action;
        const details = this.historyService.mapCreateAnalysisAction(
          type,
          props
        ) as CreateAnalysisDetails[];
        return of({ operationType: type, details }).pipe(
          map(({ operationType, details }) => {
            return registerAction({ operationType, details });
          }),
          catchError((error) =>
            of(
              historyActionError({
                error: `[createAnalysis, createAnalysisFromROIs(createAnalysisCatched$)]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
  updateAnalysisCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAnalysis),
      withLatestFrom(
        this.store$.select(selectAnalysis),
        this.store$.select(selectFindings)
      ),
      switchMap(([action, allAnalysis, allFindings]) => {
        const { type, ...props } = action;

        const analysisId = allAnalysis.find(
          (a) => a.id === props.findings[0].analysisId
        )?.id;
        const updatedFindingIds = props.findings.map((f) => f.id);

        const updatedFindings = allFindings.filter((f) =>
          updatedFindingIds.includes(f.id)
        );

        const newFindingsData = props.findings.filter((f) => !f?.id);

        const details = {
          analysisId,
          updatedFindings,
          newFindingsData,
        };

        return of({ operationType: type, details }).pipe(
          map(({ operationType, details }) => {
            return registerAction({ operationType, details });
          }),
          catchError((error) =>
            of(
              historyActionError({
                error: `[updateAnalysis(updateAnalysisCatched$)]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
  refStripActionCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markAssetAsAnalyzed),
      withLatestFrom(this.store$.select(analysisState)),
      switchMap(([action, analysisState]) => {
        const { type, ...props } = action;
        const details = {
          assetId: props.assetId,
          analysisStateId: analysisState.id,
        };
        return of({ operationType: type, details }).pipe(
          map(({ operationType, details }) => {
            return registerAction({ operationType, details });
          }),
          catchError((error) =>
            of(
              historyActionError({
                error: `[markAssetAsAnalyzed(refStripActionCatched$)]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  roiActionsCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeROIs, updateROI),
      switchMap((action) => {
        const { type, ...props } = action;
        const details = this.historyService.mapROIsAction(type, props);
        return of({ operationType: type, details }).pipe(
          map(({ operationType, details }) => {
            return registerAction({ operationType, details });
          }),
          catchError((error) =>
            of(
              historyActionError({
                error: `[removeROIs, updateROI(roiActionsCatched$)]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  updateROILabelsCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectLabel),
      withLatestFrom(
        this.store$.select(selectSelectedROIS),
        this.store$.select(selectHasSegmentationTasks)
      ),
      switchMap(([action, selectedRois, hasSegmentationTasks]) => {
        if (hasSegmentationTasks) return EMPTY;
        return of({
          operationType: OperationType.updateLabel,
          details: {
            rois: selectedRois.map((roi) => ({
              ...roi,
              selected: false,
            })) as ROI[],
          },
        }).pipe(
          map(({ operationType, details }) => {
            return registerAction({ operationType, details });
          }),
          catchError((error) =>
            of(
              historyActionError({
                error: `[selectLabel (updateROILabelsCatched$)]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );

  setROIsCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setROIs),
      switchMap((action) => {
        const { type, ...props } = action;
        return of({ operationType: type, details: { xois: props.rois } }).pipe(
          map(({ operationType, details }) => {
            return registerAction({ operationType, details });
          })
        );
      })
    )
  );

  createFindingsFromROIsCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFindingsFromROIs),
      switchMap((action) => {
        const { type, ...props } = action;

        const details = props.analysis.reduce((acc, an) => {
          const taskIds = props.selectedLabels
            .filter((l) => l.pipelineId === an.pipelineId)
            .map((l) => l.taskId);
          return [
            ...acc,
            ...(taskIds || []).map((t) => ({
              id: an.id,
              createdBy: an.createdBy,
              taskId: t,
            })),
          ];
        }, []);
        return of({ operationType: type, details }).pipe(
          map(({ operationType, details }) => {
            return registerAction({
              operationType,
              details: { findingDetails: details, rois: props.rois },
            });
          })
        );
      })
    )
  );

  analysisSyncedCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(analysisSynced),
      switchMap((action) => {
        return of({ operationType: action.type }).pipe(
          map(({ operationType }) => {
            return clearHistoryState();
          })
        );
      })
    )
  );

  switchModeCatched$ = createEffect(() =>
    this.actions$.pipe(
      ofType(switchMode),
      filter(({ mode }) => mode === Mode.ANALYSIS),
      switchMap(({ mode }) => {
        return of(mode).pipe(
          map((mode) => {
            return discardAllChanges();
          })
        );
      })
    )
  );

  discardChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(discardAllChanges),
      withLatestFrom(this.store$.select(selectActionsInHistory)),
      map(([_, actions]) => actions),
      switchMap((actions) => {
        return (
          actions.length > 0
            ? from(this.historyService.discardChanges(actions))
            : of(null)
        ).pipe(
          mergeMap((_) => {
            return [discardSync(), clearHistoryState()];
          }),
          catchError((error) =>
            of(
              historyActionError({
                error: `[discardChanges$]: ${error.message}`,
              })
            )
          )
        );
      })
    )
  );
}
